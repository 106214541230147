import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import _ from 'lodash'

import { ROUTER_PATH } from 'config.js';
import { css } from '../service/common'
import { DashboardPageTitle } from '../menujson/pagetitle_json';
// import { getMemberId, getApplicantId } from 'service/common.js';
// import '../admin/scss/components/admin/'
import './scss/components/admin/GlobalMenuMob.scss'


/**
 * Renders the top-level menus
 *
 * @param {{
 * to: string,
 * title?: string,
 * text?: string | JSX.Element,
 * children?: React.ReactNode
 * className?: string,
 * }} param0
 */
export const ModuleMenu = ({ to, title, text, children, className, disabled }) => {

    const [open, setOpen] = useState(false)

    const styles = css({
        root: {
            color: 'white',
        },
        linkParent: {
            paddingLeft: 15,
        },
        link: {
            // color: 'white',
            display: 'block',
            alignItems: 'center',
            padding: '10px 15px 10px 15px',
            marginLeft: -15,
        },
        text: {

        },
        caretBtn: {
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
        },
        caret: {
            transform: open ? `rotate(90deg)` : undefined
        }

    });

  return (
    <li className={className}>
        {disabled ? <span style={styles.text}>{text}</span> : 
            <Link title={title} to={to} onClick={() => window.location.href = to}  class='slds-context-bar__label-action'>
            <span style={styles.text}>{text}</span>
            </Link>
        }
      
      {typeof children === "function" ? children({ open: true }) : children}
    </li>
  )
}

/**
 * @typedef {ReturnType<typeof mapStateToProps>} Props
 *
 * Component to contain all links to portals and their submenus.
 *
 * Modules are marked as active, it is determined from initial substring of `window.location.pathname`.
 * Active modules will be colorized.
 *
 * NOTE:
 * Why do we use React portals in this component? Why cant we make the submenus available globally.
 *
 * Because each modules navigation menu is only available to the current opened module.
 * In other words, it is not available globally.
 *
 * To make module submenu globally available it will require massive amounts of code-refactoring.
 * So the solution for now is to use React portal.
 *
 * The caveat of using react portals is you cannot view other modules submenus
 * while browsing other modules.
 *
 * It is still recommended to make module navigation available globally
 * to solve the caveat mentioned.
 *
 * @todo When you are ready to spent large amount of time making the submenus
 * available globally, then do so. You may use the 'App.js' state, or you may
 * use Redux or use Context API to store all menus.
 *
 * @extends {React.Component<Props>}
 */
class GlobalMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
}

    /**
     * Renders menu for my details
     */
    renderMyDetailsModuleMenus() {
        const isActive = !!_.startsWith(this.props.pathname, '/schedule')
            return (
                <ModuleMenu
                    title={DashboardPageTitle.shifts}
                    to={ROUTER_PATH+'schedule/list'}
                    text={`My Shifts`}
                    className={[`slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click`, isActive && 'slds-is-active'].filter(Boolean).join(' ')}
                >
                </ModuleMenu>
            )
    }


    /**
     * Renders menu for memebr
     */
    renderMemberModuleMenus() {
        const isActive = !!_.startsWith(this.props.pathname, '/account');
        let person_id = localStorage.getItem('person_id');
        return (
            <ModuleMenu
                title={DashboardPageTitle.site}
                to={ROUTER_PATH+'account/related-listing/1/'+person_id}
                text={`Site`}
                className={[`slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click px-2`, isActive && 'slds-is-active'].filter(Boolean).join(' ')}
            >
            </ModuleMenu>
        )
    }

    /**
     * Renders menu and submenus of participant intake (aka CRM) module
     */
    renderLogoutModuleMenus() {
        // const isActive = !!_.startsWith(this.props.pathname, '/logout')
            return (
                <ModuleMenu
                    title={DashboardPageTitle.crm_admin_app}
                    to={ROUTER_PATH+'logout'}
                    text={`Logout`}
                    className='module-menu module-menu-participant-intake'
                >
                </ModuleMenu>
            )

    }

    /**
     * Render Web View Nav Bar
     */
    renderWebNavBar = () => {
        return (
            <div class="slds-context-bar slds-global-nav-bar">
                <nav class="slds-context-bar__secondary" role="navigation">
                    <ul class="slds-grid">
                        <div class="slds-context-bar__primary portalname">
                            <div class="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click slds-no-hover">
                                <span class="slds-context-bar__label-action slds-context-bar__app-name">
                                    <span class="slds-truncate" title="Portal">Portal</span>
                                </span>
                            </div>
                        </div>
                  {this.renderMyDetailsModuleMenus()}
                  {this.renderMemberModuleMenus()}
                </ul>
              </nav>
            </div>
          )
    }

    /**
     * Render Mobile View Nav Bar
     */
    renderMobNavBar = () => {
        return (
            <nav id="GlobalMenu" className="theme-light global-menu-mob">
                <ul class="">
                    <div class="slds-context-bar__primary portalname">
                            <div class="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click slds-no-hover">
                                <span class="slds-context-bar__label-action slds-context-bar__app-name">
                                    <span class="slds-truncate" title="Portal">Portal</span>
                                </span>
                            </div>
                        </div>
                    {this.renderMyDetailsModuleMenus()}
                    {this.renderMemberModuleMenus()}
                </ul>
            </nav>
          )
    }

    render() {
        // determine the nav menu bar
        var navBar;
        if (this.props.isMobile === true) {
            navBar = this.renderMobNavBar();
        } else {
            navBar = this.renderWebNavBar();
        }
        return (
            <React.Fragment>
                {navBar}
            </React.Fragment>
        )
    }
}

// This forces this component to recompute the pathname
// Don't retrieve the path within any of GlobalMenu methods or else the component
// will not add 'active' class to top-level menus when browsing other modules
const mapStateToProps = state => {
    const { pathname } = window.location
    return {
        pathname,
        // isMemberMenu: state.CommonReducer.isMember
    }
}

export default connect(mapStateToProps)(GlobalMenu)