import React, { Component } from 'react';
import moment from 'moment-timezone';
import { check_loginTime } from '../service/common';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setInterval(() => {
            check_loginTime();
        }, 1000*60)
    }
    
    render() {
        return (
            <footer className={'text-center'}>
                <div className="container">
                    <div className="row">
                        <h5> <a>Terms &amp; Conditions </a></h5>
                        <h6>© {moment().format("YYYY")} All Rights Reserved <span>Healthcare Manager</span></h6>
                    </div>
                </div>
            </footer>
        );

    }
}

export default Footer;