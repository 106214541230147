import React, { Component } from 'react';
import _ from 'lodash'
import { Link, Redirect } from 'react-router-dom';
import { ROUTER_PATH } from 'config.js';
import jQuery from 'jquery'
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { postData, css, toastMessageShow } from 'service/common.js';
import { connect } from 'react-redux'

import PropTypes from 'prop-types';

import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Tabs from '@salesforce/design-system-react/lib/components/tabs';
import TabsPanel from '@salesforce/design-system-react/lib/components/tabs/panel';

import ButtonGroup from '@salesforce/design-system-react/lib/components/button-group';
import Button from '@salesforce/design-system-react/lib/components/button';
import PageHeader from '@salesforce/design-system-react/lib/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/lib/components/page-header/control';
import Card from '@salesforce/design-system-react/lib/components/card';
import Icon from '@salesforce/design-system-react/lib/components/icon';
import AccountMembers from './AccountMembers';
import AccountContacts from './AccountContacts';
import AccountOrganisations from './AccountOrganisations';
import CreateActivityComponent from 'components/Activity/CreateActivityComponent.jsx';
import ActivityTimelineComponent from 'components/Activity/ActivityTimelineComponent.jsx';
import AttachmentCard from '../AttachmentCard';
import { get_contact_name_search_for_email_act } from "../../actions/ContactAction.jsx"
import { showArchiveAccountModal, openAddEditAccountModal } from './AccountCommon';
import AccountDetails from './AccountDetails';
import SiteDetails from './SiteDetails';

class ViewOrganisation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            org_id: '',
            tile_heading: "Organisation",
            tile_icon: "account",
            manageAccountRolesModal: false,
            openCreateModal: false,
            is_site: false,
            childorgprops: '',
            parent_org: {},
            child_par_organisation: [],
            contacts: [],
            loading: false,
            admin_permission: false
        }

        this.rootRef = React.createRef()
    }

    /**
     * fetching the org/sub-org or the site information
     */
    get_account_details = (id) => {
        this.setState({ loading: true });
        postData('sales/Account/get_account_details_for_view', { org_id: id }).then((result) => {
            if (result.status) {
                this.setState(result.data);
                this.setState({ admin_permission: result.admin_permission });
                this.setState({ tile_heading: result.data.is_site == 1 ? "Site" : "Organisation" });
                this.setState({ tile_icon: result.data.is_site == 1 ? "household" : "account" });
                this.setState({ loading: false });
            } else {
                toastMessageShow(result.error, "e");
                this.setState({ redirectTrue: true, loading: false });
            }
        });
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        var org_id = this.props.match.params.id;
        this.setState({ org_id: org_id });
        this.get_account_details(org_id);
    }

    componentDidUpdate(prevProps) {
        if (window.location.pathname != prevProps.location.pathname) {
            window.location.reload();
            var org_id = this.props.match.params.id;
            this.setState({ org_id: org_id }, () => {
                this.get_account_details(org_id);
            });
            
        }
    }

    /**
     * rendering header action buttons
     */
    actions = () => (
        <React.Fragment>
            <PageHeaderControl>
                <ButtonGroup variant="list" id="button-group-page-header-actions">
                    <Button label="Edit" onClick={() => this.showAddEditModal()} />
                    <Button label="Delete" onClick={() => this.archiveOrg(this.state.id)} />
                </ButtonGroup>
            </PageHeaderControl>
        </React.Fragment>
    );

    /**
     * Open create account organisation modal
     */
    showAddEditModal() {
        this.setState({ openCreateModal: true });
    }

    /**
     * Close the modal when user save the account and refresh the organisations table
     */
    closeAddEditAccountModal = (status) => {
        this.setState({ openCreateModal: false });

        if (status) {
            this.get_account_details(this.state.id);
        }
    }

    /**
     * to set the redirection to listing page
     */
    redirectToListing() {
        this.setState({ redirectTrue: true });
    }

    /**
     * Open archive account modal
     */
    archiveOrg(account_id) {
        showArchiveAccountModal(account_id, null, this, this.state.is_site == 1 ? "sites" : "organisations");
    }

    /**
     * rendering details tab
     */
    renderDetailsTab() {
        const styles = css({
            root: {
                border: 'none',
                paddingTop: 0,
            },
            heading: {
                marginBottom: 15,
                marginTop: 8,
            },
            headingText: {
                fontSize: 15,
                fontWeight: 'normal',
            },
            col: {
                marginBottom: 15,
            }
        })
        return (
            <TabsPanel label="Details">
                <div className="row slds-box" style={styles.root}>
                    {this.state.is_site == 0 ? <AccountDetails
                        {...this.state}
                        {...this.props}
                    /> : <SiteDetails
                        {...this.state}
                        {...this.props}
                    />}
                </div>
            </TabsPanel >
        )
    }

    /**
     * rendering related tab
     */
    renderRelatedTab() {

        if (this.state.loading == true) {
            return (
                <React.Fragment></React.Fragment>
            )
        }

        const org_id = this.props.match.params.id;
        return (
            <TabsPanel label="Related">
                <div class="slds-grid slds-grid_vertical slds_my_card">
                    <div className="slds-col">
                        <AccountContacts account_id={org_id} account_name={this.state.account_name} account_type={2} get_account_details={() => this.get_account_details(org_id)} />
                    </div>

                    {this.state.is_site == 0 ? <div className="slds-col slds-m-top_medium ">
                        <AccountOrganisations account_id={org_id} account_name={this.state.account_name} type="organisations" />
                    </div> : ''}

                    {this.state.is_site == 0 ? <div className="slds-col slds-m-top_medium ">
                        <AccountOrganisations account_id={org_id} account_name={this.state.account_name} type="sites" />
                    </div> : ''}

                    <div class="slds-col slds-m-top_medium">
                        <div className="slds-grid slds-grid_vertical">
                            <AttachmentCard
                                object_type={AttachmentCard.OBJECT_TYPE_ORGANISATION}
                                object_id={org_id}
                                attachments={this.state.attachments}
                                onSuccessUploadNewFiles={() => this.get_account_details(org_id)}
                            />
                        </div>
                    </div>

                    <div className="slds-col slds-m-top_medium ">
                        <AccountMembers account_id={org_id} />
                    </div>
                </div >
            </TabsPanel >
        )
    }

    /**
     * rendering activity block
     */
    renderActivityBlock() {
        return (
            <div className="white_bg_color slds-box">
                <div class="slds-grid slds-grid_vertical ">
                    <div class="slds-col">
                        <label>Activity</label>
                        <CreateActivityComponent
                            sales_type={"organisation"}
                            salesId={this.props.match.params.id}
                        />
                    </div>
                </div>

                <div class="slds-col  slds-m-top_medium">
                    <label>Activity</label>
                    <ActivityTimelineComponent
                        sales_type={"organisation"}
                        salesId={this.props.match.params.id}
                    />
                </div>
            </div>
        )
    }

    /**
     * rendering components
     */
    render() {
        var details = [];
        if (this.state.parent_org && this.state.parent_org.value > 0) {
            details.push({
                label: 'Parent Account',
                content: <Link to={ROUTER_PATH + `account/details/${this.state.parent_org.value}`} className="reset" style={{ color: '#006dcc' }} title={this.state.parent_org.label}>{this.state.parent_org.label}</Link>,
            });
        }

        details.push({
            label: 'Primary Contact',
            content: this.state.primary_contact_id > 0 ? <Link to={ROUTER_PATH + `contact/details/${this.state.primary_contact_id}`} className="reset" style={{ color: '#006dcc' }} title={this.state.primary_contact_name}>{this.state.primary_contact_name}</Link> : '',
        });
        details.push({
            label: 'Service Type',
            content: this.state.role_name,
        });
        details.push({
            label: 'Status',
            content: this.state.status == 1 ? "Active" : "Inactive"
        });
        const styles = css({
            asideSect__: {
                background: 'none',
                paddingLeft: 'initial',
            }
        })

        return (
            <React.Fragment>
                {(this.state.redirectTrue) ? <Redirect to='/schedule/list' /> : ''}

                <section className='asideSect__ Crm ' style={styles.asideSect__}>
                    <div className={''}></div>
                    <div className="container-fluid fixed_size">
                        <div className="row justify-content-center d-flex">

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                <div ref={this.rootRef} class="slds-grid slds-grid_vertical slds" style={{ "font-family": "Salesforce Sans, Arial, Helvetica, sans-serif", "margin-right": "-15px", "font-size": "13px;" }}>
                                    <div class="slds-col custom_page_header">
                                        <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                            <PageHeader
                                                details={details}
                                                icon={
                                                    <Icon
                                                        assistiveText={{ label: 'User' }}
                                                        category="standard"
                                                        name={this.state.tile_icon}
                                                    />
                                                }
                                                label={this.state.tile_heading}
                                                onRenderActions={this.actions}
                                                title={this.state.account_name}
                                                variant="record-home"
                                            />
                                        </IconSettings>
                                    </div>

                                    <div class="slds-col">
                                        <div className="slds-grid slds-wrap slds-gutters_x-small">
                                            <div class="slds-col slds-m-top_medium slds-size_1-of-1 slds-medium-size_7-of-12 slds-large-size_8-of-12 slds-p-right_small">
                                                <div className="white_bg_color slds-box ">
                                                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                                        <Tabs id="tabs-example-default">
                                                            {this.renderRelatedTab()}
                                                            {this.renderDetailsTab()}
                                                        </Tabs >
                                                    </IconSettings >
                                                </div>
                                            </div>


                                            <div class="slds-col slds-m-top_medium slds-size_1-of-1 slds-medium-size_5-of-12 slds-large-size_4-of-12 ">
                                                {this.renderActivityBlock()}
                                            </div>
                                        </div >
                                    </div >
                                </div >
                            </div>
                        </div>
                    </div>
                </section>
                {openAddEditAccountModal(this.props.match.params.id, null, null, null, this.state.openCreateModal, this.closeAddEditAccountModal, this.state.is_site)}
            </React.Fragment >
        );
    }
}

ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}
const mapStateToProps = state => ({
    showPageTitle: state.DepartmentReducer.activePage.pageTitle,
    showTypePage: state.DepartmentReducer.activePage.pageType,
})

const mapDispatchtoProps = (dispatch) => {
    return {
        get_contact_name_search_for_email_act: (request) => dispatch(get_contact_name_search_for_email_act(request)),
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(ViewOrganisation);