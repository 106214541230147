// @ts-nocheck
import React, { Component } from 'react';
import jQuery from "jquery";
import 'react-select-plus/dist/react-select-plus.css';
import { checkItsNotLoggedIn, postData, toastMessageShow, css, handleChange, handleShareholderNameChangeShift, queryOptionData, AjaxConfirm, handleDecimalChange, remove_access_lock, calculate_shift_duration, queryOptionDataAddNewEntity, comboboxFilterAndLimit, Confirm, getPersonId, getUUIDId } from 'service/common.js';
import { Icon, ExpandableSection, IconSettings } from '@salesforce/design-system-react';
import 'react-block-ui/style.css';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import SLDSReactSelect from 'components/salesforce/lightning/SLDSReactSelect.jsx';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
// import { SLDSISODatePicker } from '../oncallui-react-framework/salesforce/SLDSISODatePicker';
import { SLDSISODatePicker } from 'components/salesforce/lightning/SLDSISODatePicker';
import { Input, Timepicker, ButtonGroup, Dropdown, Popover, Button } from '@salesforce/design-system-react';
import moment from 'moment';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';
// import CreateContactModal from '../../admin/crm/pages/contact/CreateContactModal';
import '../../scss/components/admin/crm/pages/sales/opportunity/OpportunityDetails.scss';
// import RosterRepeatModal from './Roster/RosterRepeatModal.jsx';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import '../../scss/components/admin/crm/pages/contact/ListContact.scss';

const styles = css({
    root: {
        fontFamily: `Salesforce Sans, Arial, Helvetica, sans-serif`,
        zIndex: 12,
    },
    backdrop: {
        zIndex: 11,
    },
})

/**
 * to fetch the admin users as user types
 */
const getOptionsStaff = (e, data) => {
    return queryOptionData(e, "sales/Opportunity/get_owner_staff_search", { query: e }, 2, 1);
}

/**
 * to fetch the account contacts as user types
 */
const getOptionsAccountPersonName = (e, data) => {
    return queryOptionData(e, "schedule/ScheduleDashboard/contact_site_name_search", { query: e, person_id: getPersonId() }, 2, 1);
}

/**
 * to fetch the contacts as user types
 */
const getContactPersonName = (e, new_contact, data) => {
    return queryOptionDataAddNewEntity(e, "sales/Contact/get_contact_for_account", { account: data, query: e, limit: 10, new_contact: new_contact }, 2, 1);
}

/**
 * to fetch the roles as user types
 */
const getRoles = (e, data) => {
    // get_role_name_search get_rolename_for_account id: data.value,account_type: data.account_type,
    return queryOptionData(e, "item/document/get_rolename_for_account", { id: data.value, account_type: data.account_type, query: e }, 2, 1);
}

const GravatarOption = createClass({
    propTypes: {
        children: PropTypes.node,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
        isFocused: PropTypes.bool,
        isSelected: PropTypes.bool,
        onFocus: PropTypes.func,
        onSelect: PropTypes.func,
        option: PropTypes.object.isRequired,
    },
    handleMouseDown(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    },
    handleMouseEnter(event) {
        this.props.onFocus(this.props.option, event);
    },
    handleMouseMove(event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    },
    render() {
        var icon_ref = "/assets/salesforce-lightning-design-system/assets/icons/standard-sprite/svg/symbols.svg#contact";
        var className = "slds-icon-standard-contact";
        if (this.props.option.value == 'new contact') {
            icon_ref = "/assets/salesforce-lightning-design-system/assets/icons/utility-sprite/svg/symbols.svg#add";
            className = "slds-icon-standard-add";
        }
        else if (this.props.option.account_type == 2 && this.props.option.is_site == 0) {
            icon_ref = "/assets/salesforce-lightning-design-system/assets/icons/standard-sprite/svg/symbols.svg#account";
            className = "slds-icon-standard-account";
        }
        else if (this.props.option.account_type == 2 && this.props.option.is_site == 1) {
            icon_ref = "/assets/salesforce-lightning-design-system/assets/icons/standard-sprite/svg/symbols.svg#household";
            className = "slds-icon-standard-household";
        }
        return (
            <div className={this.props.className}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseMove={this.handleMouseMove}
                title={this.props.option.title}>

                <div role="presentation" class="slds-listbox__item">
                    <div id="option3" class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta" role="option">
                        <span class="slds-media__figure slds-listbox__option-icon">
                            <span class={"slds-icon_container " + className}>
                                <svg class="slds-icon slds-icon_small" aria-hidden="true" style={{ fill: this.props.option.value != 'new contact' ? '' : '#000' }}>
                                    <use href={icon_ref}></use>
                                </svg>
                            </span>
                        </span>
                        <span class="slds-media__body">
                            <span class="slds-listbox__option-text slds-listbox__option-text_entity">{this.props.option.label}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
});

class CreateShiftModal extends Component {

    /**
     * setting the initial prop values
     * @param {*} props
     */
    constructor(props) {
        super(props);
        // checkItsNotLoggedIn();

        const TIME_FORMAT = 'hh:mm A';
        let today = moment(new Date()).format('DD/MM/YYYY');
        this.state = {
            loading: false,
            account_disabled: false,
            repeat_open: false,
            repeat_option: '',
            repeat_days: [],
            repeat_days_selected: [],
            id: '',
            clone_id: '',

            shift_no: '',
            contact_person: [],
            account_person: '',
            owner_person: '',
            role_details: '',
            time_format: TIME_FORMAT,

            scheduled_start_date_updated: false,
            scheduled_start_date: new Date(),
            scheduled_start_date_var: '',
            scheduled_end_date: '',
            scheduled_start_time: '',
            scheduled_end_time: '',
            scheduled_travel: '',
            scheduled_reimbursement: '',
            scheduled_travel_distance: '',
            scheduled_travel_duration_hr: '',
            scheduled_travel_duration_min: '',
            actual_travel_duration_hr: '',
            actual_travel_duration_min: '',

            actual_start_date: '',
            actual_end_date: '',
            actual_start_time: '',
            actual_end_time: '',
            actual_travel: '',
            actual_reimbursement: '',
            actual_travel_distance: '',
            actual_travel_duration: '',

            description: '',
            notes: '',

            time_options: [],
            status_options: [],
            scheduled_rows: [],
            actual_rows: [],
            break_types: [],
            account_address_options: [],
            acccount_address: '',
            status: 1,

            contact_email: '',
            contact_phone: '',

            changedValue: false,
            isOpen: false,
            isDisabled: false,
            showErr: false,
            errMsg: '',
            showTimeErr: {
                scheduled_start_time: false,
                scheduled_end_time: false,
                actual_start_time: false,
                actual_end_time: false
            },
            allow_newcontact: false,
            roster_options: [],
            roster_id: '',
            selected_roster: '',
            openRosterRepeatModal: false,
            week_count: 0,
            weekfort_count: 0,
            month_count: 0,
            repeat_specific_days: false,
            inputValue: '',
            isLoadingMenuItems: false,
            selection: [],
            contact_person_options: this.setIconForList([]),
            contact_person_id: '',
            sa_line_item_options: [],
            sa_line_item_selection: [],
            service_agreement_id: '',
            sa_line_items: [],
            updateStateLineItem: false,
            show_roster: false,
            scheduled_support_type: '',
            support_type_options: [],
            serice_booking_id: '',
            scheduled_docusign_id: '',
            scheduled_docusign_url: '',
            scheduled_docusign_related: '',
            scheduled_ndis_line_item_list: [],
            missing_line_item: false,
            actual_support_type: '',
            actual_docusign_id: '',
            actual_docusign_url: '',
            actual_docusign_related: '',
            actual_ndis_line_item_list: [],
            actual_sb_status:0,
            scheduled_sb_status:0,
            scheduled_start_date_input: today,
            scheduled_end_date_input: today,
            actual_start_date_input: '',
            actual_end_date_input: '',
        }

        this.SLDSMultiSelectPopoverRef = React.createRef();

        // we'll use these refs to fix toggling slds datepicker issues
        this.datepickers = {
            scheduled_start_date: React.createRef(),
            scheduled_end_date: React.createRef(),
            actual_start_date: React.createRef(),
            actual_end_date: React.createRef(),
            repeat_hidden_date: React.createRef()
        }
    }

    /**
     * Adding a new row in the table for user to insert data of registered member
     */
    addRow = (actual_row = false) => {
        var currows = this.state.scheduled_rows;
        var actrows = this.state.actual_rows;
        var firstid = this.state.break_types[0].value;
        if (actual_row == false) {
            currows.push({ id: "", break_type: firstid, break_start_time: "", break_end_time: "", break_duration: "", duration_disabled: false, timing_disabled: false });
        }
        else {
            actrows.push({ id: "", break_type: firstid, break_start_time: "", break_end_time: "", break_duration: "", duration_disabled: false, timing_disabled: false });
        }
        this.setState({ 'scheduled_rows': currows, 'actual_rows': actrows });
    }

    /**
     * Removing a new row in the table
     */
    removeRow = (idx, actual_row = false) => {
        var currows = this.state.scheduled_rows;
        var actrows = this.state.actual_rows;
        if (actual_row == false) {
            currows.splice(idx, 1);
            this.calcActiveDuration(1);
            //Reset line items
            this.resetLineitems('scheduled');
        }
        else {
            actrows.splice(idx, 1);
            this.calcActiveDuration(2, 'actual_break_rows');
            //Reset line items
            this.resetLineitems('actual');
        }
        this.setState({ 'scheduled_rows': currows, 'actual_rows': actrows, changedValue: true });
    }

    /**
     * fetching the reference data (skills and skill levels) of member's object
     */
    get_shift_break_types = () => {
        postData("schedule/ScheduleDashboard/get_shift_break_types").then((res) => {
            if (res.status) {
                this.setState({
                    break_types: (res.data) ? res.data : []
                })
            }
        });
    }
    getAddress = (e) => {
        if (e) {
            postData("schedule/ScheduleDashboard/get_address_for_account", e).then((res) => {
                if (res.status) {
                    this.setState({
                        account_address_options: (res.data) ? res.data : [],
                        account_address: (res.data) ? res.data[0] : '',
                        unit_number: (res.data && res.data[0]) ? res.data[0].unit_number : '',
                    })
                }
            });
        }
    }

    /**
     * Get selected account contact list with default or primary
     * @param {obj} e
     * @param {boolean} reset
     */
    getContact = (e, reset, contact_person) => {
        if (e) {
            var contact_id = contact_person ? contact_person.value : '';
            postData("schedule/ScheduleDashboard/get_contact_list_for_account", { account: e, contact_id: contact_id }).then((res) => {
                if (res.status) {
                    let data = (res.data) ? res.data : [];
                    let dataWithIcon = this.setIconForList(data);

                    // Set default contact option
                    let data_default = (res.default_contact) ? res.default_contact : [];
                    let data_default_id = (res.default_contact_id) ? res.default_contact_id : '';
                    let dataDefaultWithIcon = this.setIconForList(data_default);

                    this.setState({
                        contact_person_options: dataWithIcon,
                        contact_person: dataDefaultWithIcon,
                        contact_person_id: data_default_id
                    });

                    if (reset === true) {
                        this.setState({
                            contact_person: (res[0]) ? res[0] : ''
                        })
                    }
                }
            });
        }

    }
    getServiceType = (e) => {
        if (e) {
            postData("item/document/get_rolename_for_account", e).then((res) => {
                if (res.status) {
                    let is_ndis = res.data[0] && res.data[0]["label"] === "NDIS" || false;
                    this.setState({
                        role_details_options: (res.data) ? res.data : [],
                        role_details: (res.data) ? res.data[0] : '',
                        is_ndis
                    })
                }
            });
        }

    }

    /**
     * fetching the roster id
     */
    get_roster_option = (e, roster_id) => {
        if (e) {
            postData("schedule/ScheduleDashboard/get_roster_option", { account: e }).then((res) => {
                if (res.status) {
                    this.setState({
                        roster_options: (res.data) ? res.data : []
                    }, () => {
                        if (roster_id) {
                            this.setRepeatOption(roster_id);
                        }
                    })
                }
            });
        }
    }

    /**
     * fetching the service agreement list
     */
    get_service_agreement = (reset, index) => {
        if (this.state.account_person && this.state.account_person.account_type && Number(this.state.account_person.account_type) !== 1) {
            return false;
        }
        let start_date = this.state[index + '_start_date'];
        let end_date = this.state[index + '_end_date'];
        if (start_date === '' || end_date === '' || !start_date || !end_date) {
            return false;
        }
        postData("schedule/ScheduleDashboard/get_service_agreement", { account: this.state.account_person, start_date: start_date, end_date: end_date, section: index }).then((res) => {
            if (!res.status && res.error !== 'API ERROR') {
                if(res.rule)
                {
                    if(res.rule!=1)
                    {
                        this.setState({[index+'_sb_status']:res.rule});
                    }
                }
                this.toastmsg(res.error, 'e');
            }
            let data = res.data ? res.data : [];
            this.setState({
                service_agreement_options: (res.data) ? res.data : [],
                [index + '_sa_id']: ((data.service_agreement_id) ? data.service_agreement_id : ''),
                [index + '_docusign_id']: ((data.docusign_id) ? data.docusign_id : ''),
                [index + '_docusign_url']: ((data.docusign_url) ? data.docusign_url : ''),
                [index + '_docusign_related']: ((data.docusign_related) ? data.docusign_related : ''),
                [index + '_sa_line_items']: reset === true ? [] : this.state.sa_line_items,
                updateStateLineItem: reset === true ? true : false
            }, () => {
                if (index === 'actual') {
                    this.get_line_items_for_payment('support_type', this.state.actual_support_type, 'actual');
                }
            })
        });
    }

    /**
     * Toaster Error
     * @param {str} msg 
     */
    toastmsg = (msg, type) => {
        if (type === 'e') {
            toast.error(msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        if (type === 's') {
            toast.success(msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    /**
     * Fetching the line items for payment calculation
     */
    get_line_items_for_payment = (type, value, index) => {

        let service_agreement_id = this.state[index + '_sa_id'];
        let start_date = this.state[index + '_start_date'];
        let start_time = this.state[index + '_start_time'];
        let end_date = this.state[index + '_end_date'];
        let end_time = this.state[index + '_end_time'];
        let duration = this.state[index + '_duration'];
        let rows = this.state[index + '_rows'];
        let req = {
            type: type,
            supportType: value,
            service_agreement_id: service_agreement_id,
            start_date: start_date,
            start_time: start_time,
            end_date: end_date,
            end_time: end_time,
            scheduled_duration: duration,
            account_address: this.state.account_address,
            scheduled_rows: rows,
            section: index
        };

        let ndis_line_item_list = [];
        postData("schedule/NdisPayments/get_line_items_for_payment", req).then((res) => {
            ndis_line_item_list = (res.data) ? res.data : [];
            let missing_line_item = (res.missing_line_item) ? res.missing_line_item : false;
            if (!res.status && res.error !== 'API ERROR') {
                this.toastmsg(res.error, 'e');
            }
            this.setState({
                [index + '_ndis_line_item_list']: ndis_line_item_list, scheduled_support_type: value, actual_support_type: value, [index + '_missing_line_item']: missing_line_item
            })
            if(index=='scheduled' && this.state.status>3)
            {
                this.setState({'actual_ndis_line_item_list':ndis_line_item_list})
            }
        });
    }

    /**
      *  fetching the support type
     */
    get_support_type = (reset, index) => {
        if (this.state.account_person && this.state.account_person.account_type && Number(this.state.account_person.account_type) !== 1) {
            return false;
        }
        postData("schedule/ScheduleDashboard/get_support_type").then((res) => {
            if (res.status) {
                this.setState({
                    support_type_options: (res.data) ? res.data : [],
                    [index + '_sa_line_items']: reset === true ? [] : this.state.sa_line_items,
                    updateStateLineItem: reset === true ? true : false
                })
            }
        });
    }

    /**
     * fetching the service agreement list
     */
    get_service_agreement_line_item = (sa_id, updateState) => {
        postData("schedule/ScheduleDashboard/get_service_agreement_line_item_list", { service_agreement_id: sa_id }).then((res) => {
            if (res.status) {
                this.setState({
                    sa_line_item_options: (res.data) ? res.data : [],
                    updateStateLineItem: updateState === true ? true : false
                })
                // this.SLDSMultiSelectPopoverRef.updateStateValue();
            }
        });
    }

    /**
     * calculating shift's active duration
     */
    async calcActiveDuration(cat, key, val) {
        var final_req = await calculate_shift_duration(cat, this.state, '', key, val);

        if (cat == 2) {
            this.setState({
                actual_duration: (final_req)?final_req:''
            }, () => {
               if(key == 'actual_break_rows' && final_req !='' && final_req != '00:00') {
                    this.get_line_items_for_payment('support_type', this.state.actual_support_type, 'actual');
               } 
            })
        }
        else {
            this.setState({
                scheduled_duration: (final_req) ? final_req : ''
            },()=>{
                if(this.props.id && this.state.status>3)
                {
                    this.setState({'actual_duration':this.state.scheduled_duration});
                }
                
            })
        }
    }

    /**
     * when days are selected on the repeating days modal
     * adding/removing based on selection
     */
    addRemoveSelectedRepeatDates(dateYmdHis) {
        let tempArr = [...this.state.repeat_days_selected];
        var index = tempArr.indexOf(dateYmdHis);
        if (moment(this.state.scheduled_start_date).format('YYYY-MM-DD') == dateYmdHis) {
            return;
        }
        if (index == -1) {
            tempArr.push(dateYmdHis);
        }
        else if (index > -1) {
            tempArr.splice(index, 1);
        }
        this.setState({ repeat_days_selected: tempArr });
    }

    /**
     * Format the input of date when use type manually
     * @param {date|str} value 
     */
     onChangeInputDatePicker = (value, dateYmdHis) => {
        var date_format;
        if (!value) {
            date_format = moment(dateYmdHis);
            if (!date_format.isValid()) {
                date_format = '';
            } else {
                date_format = date_format.format('DD/MM/YYYY');
            }
        } else {
            // Add slash after character length 2 and 4 using reg exp
            date_format = value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'');
        }

        return date_format;
    }
    
    /**
     * handling the change event of the data picker fields
     */
    handleChangeDatePicker = key => (dateYmdHis, e, data) => {
        let formatted_date = '';
        let value = '';
        if (e && e.target.value) {
            value = e.target.value;
        }
        // format date
        formatted_date = this.onChangeInputDatePicker(value, dateYmdHis);

        let newState = {}
        if (dateYmdHis) {
            newState[key] = dateYmdHis;
            newState[key+'_input'] = formatted_date;
            this.setState(newState, () => {
                this.setRepeatOption(this.state.roster_id);
                // Call service agreement
                if (key == 'scheduled_start_date' || key == 'scheduled_end_date') {
                    // only update if status is in-progress
                    if(this.props.id && this.state.status>3){
                        this.setState({
                            'actual_start_time':this.state.scheduled_start_time,
                            'actual_end_time':this.state.scheduled_end_time,
                            'actual_start_date':this.state.scheduled_start_date,
                            'actual_end_date':this.state.scheduled_end_date,
                            'actual_duration':this.state.scheduled_duration,
                            'actual_start_date_input': moment(this.state.scheduled_start_date).format('DD/MM/YYYY'),
                            'actual_end_date_input': moment(this.state.scheduled_end_date).format('DD/MM/YYYY')
                        })
                    }
                    this.get_service_agreement(true, 'scheduled');
                    this.get_support_type(true, 'scheduled');
                    this.resetLineitems('scheduled');
                } else {
                    this.get_service_agreement(true, 'actual');
                    this.get_support_type(true, 'actual');
                    this.resetLineitems('actual');
                }
            });
            this.setState({ scheduled_start_date_var: dateYmdHis });
            if (key == 'scheduled_start_date' && !this.state.scheduled_start_date && data) {
                this.setState({ scheduled_start_time: '06:00 AM', scheduled_start_date_updated: true, changedValue: true, scheduled_end_date: moment(data.date).format('YYYY-MM-DD') });
                document.getElementById("time_picker").className = "slds-form-element__control scheduled_start_time";
            }
            else if (key == 'scheduled_start_date') {
                this.setState({ scheduled_start_date_updated: true });
            }
            /*else if(key=='scheduled_start_date' && dateYmdHis && this.state.scheduled_start_time){
                var newstate = this.state;
                var dateState = dateYmdHis;
                var end_date_actual = this.state.scheduled_end_date?this.state.scheduled_end_date:'';
                var end_date = (this.state.scheduled_start_time === '11:50 PM') ? moment(dateState).add(1,'days').local().format('YYYY-MM-DD HH:mm') :end_date_actual;
                this.setState({ ['scheduled_end_date'] : end_date});
            }  */

            var cat = 1;
            if (key == 'actual_start_date' || key == 'actual_end_date') {
                cat = 2;
            }
            else if (key == 'repeat_hidden_date') {
                this.addRemoveSelectedRepeatDates(moment(dateYmdHis).format('YYYY-MM-DD'));
            }

            this.calcActiveDuration(cat, key, dateYmdHis);
        } else {
            
            newState[key] = '';
            newState[key+'_input'] = formatted_date;            
            this.setState(newState);
        }
    }

    resetLineitems = (index) => {
        if (index === 'schdeuled') {
            this.setState({ [index + '_support_type']: '', [index + '_ndis_line_item_list']: [] });
        } else {
            this.setState({ [index + '_ndis_line_item_list']: [] });
        }
    }
    /**
     * Set repeat option to be visible
     * @param {int} roster_id
     * @returns
     */
    setRepeatOption = (roster_id) => {
        if (this.state.scheduled_start_date !== '' && moment(this.state.scheduled_start_date).isValid() && this.state.scheduled_end_date !== '' && moment(this.state.scheduled_end_date).isValid()) {
            let roster = this.state.roster_options.filter((item, key) => item.value === roster_id).map(({ label, value, start_date, end_date }) => ({ label, value, start_date, end_date }));
            let selected_roster = roster[0] ? roster[0] : '';
            if (selected_roster.start_date && selected_roster.end_date) {
                var sc_start_date = new Date(this.state.scheduled_start_date);
                var sc_end_date = new Date(selected_roster.end_date);

                // check week repeat option
                let bet_weeks = this.diff_weeks(sc_start_date, sc_end_date);
                var addDays = 7;
                var addDaysFort = 14;
                var week_check = false;
                var weekfort_check = false;
                let week_date = moment(this.state.scheduled_start_date);
                let weekfort_date = moment(this.state.scheduled_start_date);
                for (let i = 1; i < 2; i++) {
                    week_date.add(addDays, 'days');
                    weekfort_date.add(addDaysFort, 'days');
                    // if date is greater than end date break and continue the loop
                    if (week_date.isBefore(sc_end_date) === true || week_date.isSame(sc_end_date) === true) {
                        week_check = true;
                    }

                    if (weekfort_date.isBefore(sc_end_date) === true || weekfort_date.isSame(sc_end_date) === true) {
                        weekfort_check = true;
                    }
                }

                // check month repeat option
                let all_month = this.dateRange(this.state.scheduled_start_date, selected_roster.end_date);
                var month_check = false;
                if (all_month.length > 1) {
                    var res_date = moment(selected_roster.end_date);
                    let dayINeed = moment(sc_start_date).format('dddd');
                    for (let i = 1; i < 2; i++) {
                        var mon_start_date = all_month[i];

                        var day = moment(mon_start_date).startOf('month').day(dayINeed);
                        if (day.date() > 7) day.add(7, 'd');
                        if (day.isBefore(sc_end_date) === true || day.isSame(sc_end_date) === true) {
                            month_check = true
                        }
                    }
                }
                this.setState({ week_count: week_check === true ? bet_weeks : 0, weekfort_count: weekfort_check === true ? bet_weeks : 0, month_count: month_check === true ? all_month.length : 0 });
            }
        }
    }

    /**
     * tinker with internal Datepicker state to
     * fix calendar toggling issue with multiple datepickers
     */
    handleDatePickerOpened = k => () => {
        Object.keys(this.datepickers).forEach(refKey => {
            const { current } = this.datepickers[refKey] || {}

            if (refKey !== k && current && 'instanceRef' in current) {
                current.instanceRef.setState({ isOpen: false })
            }
        })
    }

    /**
     * handling the status change event
     */
    handleChange = (value, key, index) => {
        if (key == 'contact') {
            if (value.value == 'new contact') {
                this.setState({ openCreateContactModal: true });
            } else {
                this.setState({ contact_person: value, changedValue: true }, () => {
                    this.get_service_agreement(true, 'scheduled');
                    this.get_support_type(true, 'scheduled');
                })
            }
        } else if (key === 'support_type') {
            this.get_line_items_for_payment(key, value, index);
        }else if(key == 'account_address'){
            this.state.account_address_options.map((val)=>{
                if(val.value == value){
                    this.setState({unit_number : val.unit_number})
                }
            });
            this.setState({ [key]: value });
        }else {
            this.setState({ [key]: value });
        }
    }

    handleOnBlur = (event, statetxt) => {
        if (parseInt(event.target.value) < 10 && event.target.value.length == 1) {
            this.setState({ [statetxt]: "0" + event.target.value });
        }
    }

    /**
     * To close the create or update modal
     */
    closeContactModal = (status, res_data) => {
        this.setState({ openCreateContactModal: false });
        if (res_data) {
            this.setState({ contact_person: res_data, changedValue: true });
            let contact_person = res_data[0] ? res_data[0] : res_data;
            this.getContact(this.state.account_person, false, contact_person);
        }

    }

    /**
     * To close the create or update modal
     */
    closeRosterRepeatModal = (status, res_data) => {
        this.setState({ openRosterRepeatModal: false });
        if (res_data) {
            this.setState({ changedValue: true });
        }

    }

    /**
     * when submit button is clicked on the modal
     */
    onSubmit = (e) => {
        var default_submit = false;
        if (e) {
            e.preventDefault();
            default_submit = true;
        }
        jQuery("#create_shift").validate({ /* */ });
         
        if(this.state.actual_start_date && !this.state['actual_start_time']||this.state.actual_end_date && !this.state['actual_end_time'])
        {
            this.toastmsg('Actual times needed to entered','e');
            return;
        }
        if(this.state.scheduled_start_date &&! this.state['scheduled_start_time']||!this.state['scheduled_end_time'])
        {
            this.toastmsg('Schedules times needed to entered','e');
            return;
        }
        if (default_submit == false && this.state.repeat_option == 3 && this.state.repeat_days_selected.length == 0) {
            this.toastmsg("Please select atleast one day to repeat the shift", 'e');
            this.setState({ repeat_open: true });
            return;
        }

        let roster_id = this.state.roster_id;
        let roster = this.state.roster_options.filter((item, key) => item.value === roster_id).map(({ label, value, start_date, end_date }) => ({ label, value, start_date, end_date }));
        let selected_roster = roster[0] ? roster[0] : '';
        if (selected_roster.start_date && selected_roster.end_date) {
            let start_date_ros = moment(selected_roster.start_date);
            let end_date_ros = moment(selected_roster.end_date);
            let start_date = moment(this.state.scheduled_start_date);
            let end_date = moment(this.state.scheduled_end_date);
            if (start_date_ros.isBefore(start_date) === false && start_date_ros.isSame(start_date, 'day') === false) {
                this.toastmsg("Schedule start date must be greater than roster start date", 'e');
                return;
            }
            if (end_date_ros.isAfter(start_date) === false && end_date_ros.isSame(start_date, 'day') === false) {
                this.toastmsg("Schedule start date must be less than roster end date", 'e');
                return;
            }
            if (end_date_ros.isAfter(end_date) === false && end_date_ros.isSame(end_date, 'day') === false) {
                this.toastmsg("Schedule end date must be less than roster end date", 'e');
                return;
            }
            if (start_date_ros.isBefore(end_date) === false && start_date_ros.isSame(end_date, 'day') === false) {
                this.toastmsg("Schedule end date must be greater than roster start date", 'e');
                return;
            }
        }

        // if (this.state.account_person && this.state.account_person.account_type && Number(this.state.account_person.account_type) === 1 && this.state.service_agreement_id != 'null' && this.state.service_agreement_id != null && this.state.service_agreement_id != '' && this.state.sa_line_items.length < 1) {
        //     toastMessageShow("Please Select NDIS line item ", "e");
        //         return;
        // }

        this.setState({ validation_calls: true })
        if (!this.state.loading && jQuery("#create_shift").valid()) {
            this.setState({ loading: true });
            let contact = (this.state.contact_person) ? this.state.contact_person : '';
            let contact_id = '';
            if (contact && contact[0]) {
                contact_id = contact[0].value;
            }

            let service_agreement_id = this.state.service_agreement_id;
            let sa_line_items = this.state.sa_line_items
            if (this.state.account_person && this.state.account_person.account_type && Number(this.state.account_person.account_type) !== 1) {
                service_agreement_id = '';
                sa_line_items = [];
            }

            var req = {
                id: this.state.id,
                shift_no: this.state.shift_no,
                contact_id: contact_id,
                account_address: (this.state.account_address) ? this.state.account_address.value : '',
                full_account_address: (this.state.account_address) ? this.state.account_address : '',
                account_id: (this.state.account_person) ? this.state.account_person.value : '',
                account_type: (this.state.account_person) ? this.state.account_person.account_type : '',
                // owner_id: (this.state.owner_person) ? this.state.owner_person.value : '',
                owner_id:  '',
                role_id: (this.state.role_details) ? this.state.role_details.value : '',
                scheduled_start_date: this.state.scheduled_start_date && moment(this.state.scheduled_start_date).isValid() ? moment(this.state.scheduled_start_date).format('YYYY-MM-DD') : this.state.scheduled_start_date,
                scheduled_end_date: this.state.scheduled_end_date && moment(this.state.scheduled_start_date).isValid() ? moment(this.state.scheduled_end_date).format('YYYY-MM-DD') : this.state.scheduled_end_date,
                scheduled_start_time: this.state.scheduled_start_time,
                scheduled_end_time: this.state.scheduled_end_time,
                scheduled_travel: this.state.scheduled_travel,
                scheduled_reimbursement: this.state.scheduled_reimbursement,
                scheduled_travel_distance: this.state.scheduled_travel_distance,
                scheduled_travel_duration_hr: this.state.scheduled_travel_duration_hr,
                scheduled_travel_duration_min: this.state.scheduled_travel_duration_min,
                actual_travel_duration_hr: this.state.actual_travel_duration_hr,
                actual_travel_duration_min: this.state.actual_travel_duration_min,
                actual_start_date: this.state.actual_start_date ? moment(this.state.actual_start_date).format('YYYY-MM-DD') : '',
                actual_end_date: this.state.actual_end_date ? moment(this.state.actual_end_date).format('YYYY-MM-DD') : '',
                actual_start_time: this.state.actual_start_time,
                actual_end_time: this.state.actual_end_time,
                actual_travel: this.state.actual_travel,
                actual_reimbursement: this.state.actual_reimbursement,
                actual_travel_distance: this.state.actual_travel_distance,
                actual_travel_duration: this.state.actual_travel_duration,
                description: this.state.description,
                notes: this.state.notes,
                status: this.state.status,
                repeat_option: (default_submit == true) ? '' : this.state.repeat_option,
                repeat_days_selected: (default_submit == true) ? '' : this.state.repeat_days_selected,
                scheduled_rows: this.state.scheduled_rows,
                actual_rows: this.state.actual_rows,
                contact_email: this.state.contact_email,
                contact_phone: this.state.contact_phone,
                roster_id: this.state.roster_id,
                repeat_specific_days: this.state.repeat_specific_days,

                scheduled_sa_id: this.state.scheduled_sa_id,
                scheduled_docusign_id: this.state.scheduled_docusign_id,
                scheduled_support_type: this.state.scheduled_support_type,
                scheduled_ndis_line_item_list: this.state.scheduled_ndis_line_item_list,
                scheduled_missing_line_item: this.state.scheduled_missing_line_item,

                actual_sa_id: this.state.actual_sa_id,
                actual_docusign_id: this.state.actual_docusign_id,
                actual_support_type: this.state.actual_support_type,
                actual_ndis_line_item_list: this.state.actual_ndis_line_item_list,
                actual_missing_line_item: this.state.actual_missing_line_item,
                actual_sb_status:this.state.actual_sb_status,
                scheduled_sb_status:this.state.scheduled_sb_status
            }
            var url = 'schedule/ScheduleDashboard/create_update_shift';
            postData(url, req).then((result) => {
                if (result.status) {
                    let msg = result.hasOwnProperty('msg') ? result.msg : '';
                    toastMessageShow(result.msg, "s");
                    this.props.closeModal(true);
                    setTimeout(() => {
                        this.ndisPaymentErr(result);
                    }, 1500);
                } else if (result.status == false && result.account_shift_overlap == true) {
                    var confirm_msg = result.error + ', Do you want to continue?';
                    req.skip_account_shift_overlap = true;
                    AjaxConfirm(req, confirm_msg, `schedule/ScheduleDashboard/create_update_shift`, { confirm: 'Continue', heading_title: this.props.id ? "Update Shift" : "Create New Shift" }).then(conf_result => {
                        if (conf_result.status) {
                            let msg = conf_result.hasOwnProperty('msg') ? conf_result.msg : '';
                            toastMessageShow(conf_result.msg, "s");
                            this.props.closeModal(true);
                            this.ndisPaymentErr(conf_result);
                        }
                        else {
                            toastMessageShow(conf_result.error, "e");
                        }
                    })
                } else if (!result.status && (result.error.scheduled_section || result.error.scheduled_breaks)) {
                    if (result.error.scheduled_section) {
                        let errorParse = JSON.parse(result.error.scheduled_section);
                        toastMessageShow(errorParse.msg, "e");
                    }
                    if (result.error.scheduled_breaks) {
                        let errorParse = JSON.parse(result.error.scheduled_breaks);
                        toastMessageShow(errorParse.msg, "e");
                    }
                    
                }else {
                    toastMessageShow(result.error, "e");
                }
                this.setState({ loading: false });
            });
        }
    }

    /**
     * Show error msg if any error found
     * @param {array} result 
     */
    ndisPaymentErr = (result) => {
        if (result.ndisErrUrl) {            
            let err_msg = 'NDIS Payment Errors are found in one (or) more repeated shifts. Download the file to view the errors.';
            Confirm(err_msg, { confirm: 'Download', heading_title: "Errors Found" }).then(msg_result => {
                if (msg_result.status === true) {
                    window.open(result.ndisErrUrl, "_blank");
                }                
            });
        }
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        if (this.props.id) {
            this.handleBrowserClosed();
            this.get_shift_details(this.props.id);
            this.setState({ account_disabled: true, show_roster: true });
        }
        else if (this.props.clone_id) {
            this.get_shift_details(this.props.clone_id);
            this.setState({account_disabled: false, show_roster: false, id: ''});
        }
        else {
            this.get_current_user();
        }

        if (this.props.roster) {
            var roster = this.props.roster;
            let show_roster = this.props.id ? true : this.props.page_name == 'participants' ? false : true;
            let new_contact = false;
            if (roster.account.account_type == 1) {
                new_contact = false;
            }
            this.setState({ account_person: roster.account, roster_id: roster.roster_id, owner_person: roster.owner, account_disabled: true, allow_newcontact: new_contact, show_roster: show_roster }, () => {
                if (roster.account) {
                    this.getAddress(roster.account);
                    let account_person = roster.account;
                    this.get_roster_option(account_person, roster.roster_id);
                    this.getServiceType(account_person);
                    this.getContact(this.state.account_person, false);
                    this.get_support_type(false, 'scheduled');
                    this.get_service_agreement(false, 'scheduled');
                }
            });

        }
        this.get_shift_statuses();
        this.get_shift_break_types();
        let currentDateTime = moment().local().format('YYYY-MM-DD HH:mm');
        this.setState({ scheduled_end_date: currentDateTime, scheduled_start_time: '06:00 AM' })
    }

    setValidatedTime(e, key, duration, durAct) {
        var selTime = this.state[key];
        let startTime = selTime;
        if (selTime != '' && this.state.showTimeErr[key]) {
            startTime = this.getFormatTime(startTime, key);
            if (isNaN(parseInt(startTime))) {
                console.log('Invalid Time');
            } else {
                this.setState({ [key]: startTime }, () => {
                    if (duration === true) {
                        this.calcActiveDuration(durAct, key, startTime);
                    }
                    if (key === 'actual_start_time' || key === 'actual_end_time') {
                        this.get_service_agreement(true, 'actual');
                    }
                });
            }
        }
    }

    setBreakTime(e, key, objVal, idx, inputStr, eventObj) {
        var row = key;
        var time = this.state[key];
        var selTime = time[idx];
        if (selTime != '') {
            let startTime = selTime[objVal];
            startTime = this.getFormatTime(startTime, key);
            if (isNaN(parseInt(startTime))) {
                console.log('Invalid Time');
            } else {
                time[idx][objVal] = startTime;

                this.setState({ key: time}, () => {
                    handleShareholderNameChangeShift(eventObj, key, idx, objVal, startTime);                    
                    if (row === 'actual_rows') { 
                        this.calcActiveDuration(2, 'actual_break_rows');                        
                     }  
                });
            }
        }
    }

    handleTimepicker = (date, selTime, key) => {

        if (date != 'Invalid Date' && selTime != '') {
            var start_time = moment(date).local().format(this.state.time_format);
            if (key === 'scheduled_start_time' || key === 'actual_start_time') {
                var newstate = this.state;
                var dateState = (key === 'scheduled_start_time') ? 'scheduled_start_date' : 'actual_start_date';
                let date_updated = this.state.scheduled_start_date_updated === true ? newstate[dateState] : date;
                var end_date = (start_time === '11:50 PM') ? moment(date_updated).add(1, 'days').local().format('YYYY-MM-DD HH:mm') : newstate[dateState];
                var updateState = (key === 'scheduled_start_time') ? 'scheduled_end_date' : 'actual_end_date';
                let end_date_input = moment(end_date).format('DD/MM/YYYY');
                if (moment(moment(end_date).format('DD/MM/YYYY'), 'DD/MM/YYYY', true).isValid() == true ) {
                    this.setState({ [updateState]: end_date, [updateState+'_input']: end_date_input });
                }
            }
            if (key === 'scheduled_start_time' || key === 'scheduled_end_time') {
                //Reset line items
                this.resetLineitems('scheduled');
            } else {
                this.resetLineitems('actual');
            }
            var getTime = selTime.slice(-2);
            const current = this.state.showTimeErr;
            current[key] = (getTime === 'am' || getTime === 'pm' || getTime === 'AM' || getTime === 'PM') ? false : true;

            var isValid = /^(((0[1-9])|(1[0-2])):([0-5])(0|5)\s(A|P)M)?$/.test(selTime);
            var isValidAlt = /^(([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9]))?$/.test(selTime);
            let time_hrminutes = selTime;
            if (isValid === true || isValidAlt === true) {
                let time_split = selTime.split(':');
                let time_min_split = time_split[1].split(' ');
                let time_hr_temp = time_split[0];
                let time_min_temp = time_min_split[0];
                let time_for_temp = time_min_split[1];
                time_hrminutes = String(time_hr_temp).padStart(2, '0') + ':' + String(time_min_temp).padEnd(2, '0') + ' ' + time_for_temp;
            }
            var isValidAltN = /^(10|11|12|[1-9]):[0-5][0-9]\s(A|P)M$/.test(selTime);
            if (isValid === true || isValidAlt === true || isValidAltN === true) {
                if (key === 'actual_start_time' || key === 'actual_end_time') {
                    this.get_service_agreement(true, 'actual');
                }
            }
            

            this.setState({ [key]: time_hrminutes, changedValue: true, showTimeErr: current },()=>{
                if(this.props.id && this.state.status>3){
                    if (key === 'scheduled_start_time' || key === 'scheduled_end_time') {
                            this.setState({
                                'actual_start_time':this.state.scheduled_start_time,
                                'actual_end_time':this.state.scheduled_end_time,
                                'actual_start_date':this.state.scheduled_start_date,
                                'actual_end_date':this.state.scheduled_end_date,
                                'actual_duration':this.state.scheduled_duration});
                            }
                }
             
            });

        } else {
            const current = this.state.showTimeErr;
            current[key] = true;
            // this.get_end_time(selTime.substring(0,2),selTime.substring(2,selTime.length),selTime.slice(-2));

            this.setState({ [key]: selTime, errMsg: 'Invalid Time', showTimeErr: current, changedValue: true })
        }
    }

    /**
     * Get time format
     * @param {str} selTime
     */
    getFormatTime = (selTime, key) => {
        var isValid = /^(((0[1-9])|(1[0-2])):([0-5])(0|5)\s(A|P)M)?$/.test(selTime);
        let startTime;
        if (isValid === true && selTime != '') {
            let time_split = selTime.split(':');
            let time_min_split = time_split[1].split(' ');
            let time_hr_temp = time_split[0];
            let time_min_temp = time_min_split[0];
            let time_for_temp = time_min_split[1];
            startTime = this.get_end_time(time_hr_temp, time_min_temp, time_for_temp, key);
        } else {
            startTime = this.get_end_time(selTime.substring(0, 2), selTime.substring(2, selTime.length), selTime.slice(-2), key);
        }
        return startTime;
    }

    get_end_time(hrs, mins, format, key) {
        let hr = '';
        let minutes = parseInt(mins);
        let hrminutes = '';

        if (parseInt(hrs) > 12) {
            hr = parseInt(hrs - 12);
            if (isNaN(minutes)) minutes = '00';
            hrminutes = String(hr).padStart(2, '0') + ':' + String(minutes).padEnd(2, '0') + 'PM';
            if (hr > 11) {
                hrminutes = hrminutes.replace("AM", "PM");
            }
        } else {
            hr = parseInt(hrs);
            if (hr < 10) {
                if (isNaN(minutes)) minutes = '00';

                if (format === 'am' || format === 'pm' || format === 'AM' || format === 'PM') {
                    format = format.toUpperCase();
                } else {
                    format = 'AM';
                }
                hrminutes = String(hr).padStart(2, '0') + ':' + String(minutes).padEnd(2, '0') + ' ' + format;
            } else {
                if (isNaN(minutes)) minutes = '00';

                if (format === 'am' || format === 'pm' || format === 'AM' || format === 'PM') {
                    format = format.toUpperCase();
                } else {
                    format = 'AM';
                }
                hrminutes = String(hr).padStart(2, '0') + ':' + String(minutes).padEnd(2, '0') + ' ' + format;
            }
        }
        if (isNaN(parseInt(hrminutes))) {
            const current = this.state.showTimeErr;
            current[key] = true;
            this.setState({ errMsg: 'Invalid Time', showTimeErr: current, changedValue: true })
        } else {
            const current = this.state.showTimeErr;
            current[key] = false;
            this.setState({ showTimeErr: current, changedValue: true });
        }
        return hrminutes;
    }

    /**
     * Setup the `beforeunload` event listener
     */
    handleBrowserClosed = () => {
        window.addEventListener("beforeunload", (e) => {
            e.preventDefault();
            remove_access_lock('shift', this.state.id);
        });
    };

    /**
     * fetching the current user details
     */
    get_current_user = () => {
        postData("schedule/ScheduleDashboard/get_current_admin_user_details", {user_type:3}).then((res) => {
            if (res.status) {
                this.setState({
                    owner_person: (res.data) ? res.data : ''
                })
            }
        });
    }
 
    /**
     * fetching the shift statuses
     */
    get_shift_statuses = () => {
        postData("schedule/ScheduleDashboard/get_shift_statuses", {}).then((res) => {
            if (res.status) {
                this.setState({
                    status_options: (res.data) ? res.data : []
                })
            }
        });
    }

    /**
     * fetching the member details if the modal is opened in the edit mode
     */
    get_shift_details = (passid) => {
        postData('schedule/ScheduleDashboard/get_shift_details', { id: passid, clone_id: this.props.clone_id, shift_lock: true }).then((result) => {
            if (result.status) {

                let scheduled_travel_duration = result.data.scheduled_travel_duration;
                if (scheduled_travel_duration && scheduled_travel_duration.indexOf(":") !== -1) {
                    let scheDuration = scheduled_travel_duration.split(":");
                    result.data.scheduled_travel_duration_hr = scheDuration[0];
                    result.data.scheduled_travel_duration_min = scheDuration[1];
                }

                let actual_travel_duration = result.data.actual_travel_duration;

                if (actual_travel_duration && actual_travel_duration.indexOf(":") !== -1) {
                    let actDuration = actual_travel_duration.split(":");
                    result.data.actual_travel_duration_hr = actDuration[0];
                    result.data.actual_travel_duration_min = actDuration[1];
                }
                this.setState(result.data,()=>{
                    let sch_start_date_input = moment(result.data.scheduled_start_date).format('DD/MM/YYYY');
                    let sch_end_date_input = moment(result.data.scheduled_end_date).format('DD/MM/YYYY');
                    let act_start_date_input = '';
                    let act_end_date_input = '';
                    if (!result.data.actual_start_date) {
                        act_start_date_input = '';
                    } else {
                        act_start_date_input = moment(result.data.actual_start_date).format('DD/MM/YYYY');
                    }

                    if (!result.data.actual_end_date) {
                        act_end_date_input = '';
                    } else {
                        act_end_date_input = moment(result.data.actual_end_date).format('DD/MM/YYYY');
                    }

                    this.setState({
                        scheduled_start_date_input: sch_start_date_input,
                        scheduled_end_date_input: sch_end_date_input,
                        actual_start_date_input: act_start_date_input,
                        actual_end_date_input: act_end_date_input
                    });
                });
                let is_ndis = false;
                if (result.data.rolelabel === "NDIS") {
                    is_ndis = true;
                }
                this.setState({...result.data, is_ndis},()=>{
                    if(this.state.status>3)
                    {
                        this.get_line_items_for_payment('support_type', this.state.actual_support_type, 'actual');
                    }
                });
                if (result.data.account_person) {
                    this.getAddress(result.data.account_person);
                    this.getContact(result.data.account_person, false, result.data.contact_person);
                    let account_person = result.data.account_person;
                    this.get_roster_option(account_person, result.data.roster_id);
                    this.get_support_type(false);

                    if (this.props.clone_id) {
                        this.get_service_agreement(false, 'scheduled');
                    }
                }
                if (this.props.clone_id) {
                    this.setState({ shift_no: '' })
                }

            } else {
                if (result.error !== 'API ERROR') {
                    this.toastmsg(result.error, 'e');
                }
                this.props.closeModal();
            }
        });
    }

    /**
     * Update Line Item checked
     */
    updateSteaLineItemFalse = () => {
        this.setState({ updateStateLineItem: false });
    }

    delayOptionsLoad = () => {
        // A promise should be used here for asynchronous callbacks
        setTimeout(() => {
            this.setState({ isLoadingMenuItems: false });
        }, 1000);
    };

    /**
     * Render Contact input field
     */
    renderContactField = () => {
        return (
            <div className="col-lg-6 col-sm-6">
                <div className="slds-form-element">
                    <div className="slds-form-element__control">
                        <div className="">
                            {this.renderComboBox('Contact', 'contact_person', 'contact_person_id')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Render To contacts
     * @param {str} label
     * @param {str} stateName - selection state
     * @param {str} valueName - value state
     */
    renderComboBox = (label, stateName, valueName) => {
        var contact_add_new_entity = [];
        if (this.state.allow_newcontact) {
            contact_add_new_entity = [
                {
                    id: 'contact-add-id-1',
                    icon: (
                        <Icon
                            assistiveText={{ label: 'Add' }}
                            category="utility"
                            size="x-small"
                            name="add"
                        />
                    ),
                    label: 'New Contact',
                    value: 'new_contact'
                },
            ];
        }
        return (
            <Combobox
                id="combobox-to-contact"
                classNameContainer="combox_box-to-cus-contact"
                events={{
                    onChange: (event, { value }) => {
                        if (this.props.action) {
                            this.props.action('onChange')(event, value);
                        }
                        this.setState({ [valueName]: value, isLoadingMenuItems: true });
                    },
                    onRequestRemoveSelectedOption: (event, data) => {
                        this.setState({
                            [valueName]: '',
                            [stateName]: data.selection,
                        });
                    },
                    onBlur: (event, data) => {
                        this.setState({
                            [valueName]: '',
                        });
                    },
                    onSubmit: (event, { value }) => {
                        if (this.props.action) {
                            this.props.action('onChange')(event, value);
                        }
                        this.setState({
                            [valueName]: '',
                            [stateName]: [
                                ...this.state[stateName],
                                {
                                    label: value,
                                    icon: (
                                        <Icon
                                            assistiveText="Account"
                                            category="standard"
                                            name="account"
                                        />
                                    ),
                                },
                            ],
                        });
                    },
                    onSelect: (event, data) => {
                        if (this.props.action) {
                            this.props.action('onSelect')(
                                event,
                                ...Object.keys(data).map((key) => data[key])
                            );
                        }
                        if (data.selection && data.selection[0] && data.selection[0].value === 'new_contact') {
                            this.setState({ openCreateContactModal: true });
                        } else {
                            this.setState({
                                [valueName]: '',
                                [stateName]: data.selection,
                            }, () => {
                                this.get_service_agreement(true, 'scheduled');
                                this.get_service_agreement(true, 'actual');
                                this.get_support_type(true, 'scheduled');
                                this.get_support_type(true, 'actual');
                            });
                        }
                    },
                }}
                labels={{
                    label: '  ' + label,
                    placeholder: 'Search ' + label,
                }}
                menuItemVisibleLength={5}
                options={
                    comboboxFilterAndLimit(
                        this.state[valueName],
                        100,
                        this.state.contact_person_options,
                        this.state[stateName],
                    )
                }
                selection={this.state[stateName]}
                value={this.state[valueName]}
                optionsAddItem={contact_add_new_entity}
                required={true}
                name="shift_contact"
                disabled={this.state.account_person === '' || !this.state.account_person ? true : false}
                variant="inline-listbox"
            />
        );
    }

    setIconForList(option) {
        return option.map((elem) => ({
            ...elem,
            ...{
                icon: (
                    <Icon
                        assistiveText={{ label: 'Account' }}
                        category="standard"
                        name={elem.type}
                    />
                ),
            },
        }));
    }

    /**
     * rendering general part of the schedule form
     */
     RenderGeneralSection() {
        return (
            <React.Fragment>
                <ExpandableSection
					id="default-expandable-section"
					title="General Information"
				>
                <div className="container-fluid">
               { this.props.id&&( <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" >
                                    <abbr className="slds-required" title="required">* </abbr>Shift No:
                                </label>
                                <div className="slds-form-element__control">
                                {this.state.shift_no || ''}
                                </div>
                            </div>
                        </div>
                    </div>)}
                    <div className="row py-2">
                    <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" >
                                    <abbr className="slds-required" title="required">* </abbr>Account (Site) Name
                                </label>
                                <div className="slds-form-element">
                                    <SLDSReactSelect.Async clearable={false}
                                        className="SLDS_custom_Select default_validation"
                                        value={this.state.account_person}
                                        disabled={this.state.account_disabled}
                                        cache={false}
                                        loadOptions={(e) => getOptionsAccountPersonName(e, [])}
                                        onChange={(e) => {
                                            var new_contact = false;
                                            if (e && Number(e.account_type) === 1) {
                                                new_contact = false;
                                            }
                                            this.setState({ account_person: e, allow_newcontact: new_contact, changedValue: true },()=>{this.getAddress(e);this.getServiceType(e);this.get_support_type(true);this.get_service_agreement(true,'scheduled');this.get_service_agreement(true,'actual');});
                                            this.get_roster_option(e);
                                            this.getContact(e,false);
                                            }
                                        }
                                        placeholder="Please Search"
                                        required={true}
                                        optionComponent={GravatarOption}
                                        name="shift_account"
                                    />
                                </div>
                            </div>
                        </div>
                        {this.renderContactField()}
                        {/* <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01">Owner</label>
                                <div className="slds-form-element__control">
                                    <SLDSReactSelect.Async clearable={false}
                                        className="SLDS_custom_Select default_validation"
                                        value={this.state.owner_person}
                                        cache={false}
                                        loadOptions={(e) => getOptionsStaff(e, [])}
                                        onChange={(e) => this.setState({ owner_person: e, changedValue: true })}
                                        placeholder="Please Search"
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="row py-2">

                        {this.renderContactField()}
                    </div> */}
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">

                                <div className="slds-form-element">
                                    <div className="slds-form-element__control">
                                        <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                            Apartment/Unit number</label>
                                        <div className="slds-form-element__control">
                                            <input type="text"
                                                name="unit_number"
                                                placeholder="Apartment/Unit number"
                                                onChange={(e) => handleChange(this, e)}
                                                value={this.state.unit_number || ''}
                                                maxLength="30"
                                                className="slds-input"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                            </div>
                                </div>

                                <div className="col-lg-6 col-sm-6">
                                    <div className="slds-form-element">
                                        <label className="slds-form-element__label" htmlFor="select-01"><abbr className="slds-required" title="required"></abbr>Account Address</label>
                                        <div className="slds-form-element__control">
                                            <div className="SLDS_date_picker_width">
                                                <SLDSReactSelect
                                                    simpleValue={true}
                                                    className="custom_select default_validation"
                                                    options={this.state.account_address_options}
                                                    onChange={value => {
                                                        this.handleChange(value, 'account_address')
                                                        this.setState({ changedValue: true });
                                                    }}
                                                    value={this.state.account_address}
                                                    clearable={false}
                                                    searchable={false}
                                                    placeholder="Please Select"
                                                    required={true}
                                                    disabled={this.state.account_person === '' ? true : false}
                                                    name="Address"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                    <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">

                        <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01"><abbr className="slds-required" title="required">* </abbr>Service Type</label>
                                <div className="slds-form-element__control">

                                    <div className="">
                                           <SLDSReactSelect
                                                simpleValue={true}
                                                className="custom_select default_validation"
                                                options={this.state.role_details_options}
                                                onChange={value => {
                                                    let is_ndis = false;
                                                    this.state.role_details_options.map(option => {
                                                        if (option.label === "NDIS" && value == option.value) {
                                                            is_ndis = true;
                                                        }
                                                    })
                                                    this.handleChange(value, 'role_details')
                                                    this.setState({ changedValue: true, is_ndis });
                                                }}
                                                value={this.state.role_details}
                                                clearable={false}
                                                searchable={false}
                                                placeholder="Please Select"
                                                required={true}
                                                disabled={this.state.account_person === '' ? true : false}
                                                name="Service Type"
                                            />
                                    </div>
                                </div>
                            </div>                                
                            </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01">Description</label>
                                <div className="slds-form-element__control">
                                    <textarea
                                        required={false}
                                        className="slds-textarea"
                                        name="description"
                                        placeholder="Description"
                                        onChange={(e) => {
                                            this.handleChange(e.target.value, "description")
                                            this.setState({ changedValue: true });
                                        }}
                                        value= {this.state.description ?  this.state.description : ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="select-01">
                                        <abbr class="slds-required" title="required">* </abbr>Status</label>
                                    <div className="slds-form-element__control">
                                        <div className="">
                                            <SLDSReactSelect
                                                simpleValue={true}
                                                className="custom_select default_validation"
                                                options={this.state.status_options}
                                                onChange={(value) => {
                                                    this.handleChange(value, 'status')
                                                    this.setState({ changedValue: true });
                                                }}
                                                value={this.state.status || ''}
                                                clearable={false}
                                                searchable={false}
                                                placeholder="Please Select"
                                                required={true}
                                                name="Status"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    </div> */}
                </div>
                </ExpandableSection>
            </React.Fragment>
        )
    }

    /**
     * rendering sechedule timing part of the schedule form
     */
    RenderScheduleSection() {
        return (
            <React.Fragment>
                <ExpandableSection
                    id="default-expandable-section"
                    title="Scheduled Times"
                >
                    <div className="container-fluid">
                        <div className="row py-2">
                            <div className="col-sm-3">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                        <abbr className="slds-required" title="required">* </abbr>Scheduled Start Date</label>
                                    <div className="slds-form-element__control">
                                        <div className="SLDS_date_picker_width" onKeyDown={(e) => {
                                            if (e.key === 'Tab') {

                                                const { current } = this.datepickers['scheduled_start_date'] || {}
                                                if (current && 'instanceRef' in current) {
                                                    current.instanceRef.setState({ isOpen: false });
                                                }
                                            }
                                        }}>
                                            <SLDSISODatePicker
                                                type="date"
                                                ref={this.datepickers.scheduled_start_date} // !important: this is needed by this custom SLDSISODatePicker
                                                className="date_picker"
                                                placeholder="DD/MM/YYYY"
                                                onChange={this.handleChangeDatePicker('scheduled_start_date')}
                                                onOpen={this.handleDatePickerOpened('scheduled_start_date')}
                                                onClear={this.handleChangeDatePicker('scheduled_start_date')}
                                                value={this.state.scheduled_start_date}
                                                input={<Input name="scheduled_start_date" />}
                                                inputProps={{
                                                    name: "scheduled_start_date",
                                                    value: this.state.scheduled_start_date_input,
                                                    maxLength: 10
                                                }}
                                                disabled={this.state.isDisabled}
                                                clearable={false}
                                                relativeYearFrom={0}
                                                relativeYearTo={3}
                                                dateDisabled={(data) =>
                                                    moment(data.date).isSame(moment(), 'day') && moment(data.date).isBefore() ? false : moment(data.date).isBefore() ? true : false
                                                }
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="slds-form-element">
                                    <div className="slds-form-element__control timepickerselected scheduled_start_time" id="time_picker" tabIndex="1" onBlur={(e) => this.setValidatedTime(e, 'scheduled_start_time', true, 1)}>
                                        <Timepicker
                                            label={<span>&nbsp;Start Time</span>}
                                            stepInMinutes={10}
                                            required={true}
                                            name="scheduled_start_time"
                                            id="scheduled_start_time"
                                            menuPosition="relative"
                                            strValue={this.state.scheduled_start_time}
                                            formatter={(date) => {
                                                if (date) {
                                                    return date.toLocaleTimeString(navigator.language, {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    });
                                                }
                                                return null;
                                            }}
                                            onDateChange={(date, inputStr) => {
                                                document.getElementById("time_picker").className = "slds-form-element__control timepickerselected scheduled_start_time";
                                                this.handleTimepicker(date, inputStr, 'scheduled_start_time')
                                                this.calcActiveDuration(1, "scheduled_start_time", inputStr);
                                            }}
                                        />


                                    </div>
                                    {this.state.showTimeErr['scheduled_start_time'] && <span style={{ color: "red" }}>{this.state.errMsg}</span>}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1"><abbr className="slds-required" title="required">* </abbr>Scheduled End Date</label>
                                    <div className="slds-form-element__control">
                                        <div className="SLDS_date_picker_width" onKeyDown={(e) => {
                                            if (e.key === 'Tab') {
                                                const { current } = this.datepickers['scheduled_end_date'] || {}
                                                if (current && 'instanceRef' in current) {
                                                    current.instanceRef.setState({ isOpen: false })
                                                }
                                            }
                                        }}>
                                            <SLDSISODatePicker
                                                type="date"
                                                ref={this.datepickers.scheduled_end_date} // !important: this is needed by this custom SLDSISODatePicker
                                                className="date_picker"
                                                placeholder="DD/MM/YYYY"
                                                onChange={this.handleChangeDatePicker('scheduled_end_date')}
                                                onOpen={this.handleDatePickerOpened('scheduled_end_date')}
                                                onClear={this.handleChangeDatePicker('scheduled_end_date')}
                                                value={this.state.scheduled_end_date}
                                                input={<Input name="scheduled_end_date" />}
                                                inputProps={{
                                                    name: "scheduled_end_date",
                                                    value: this.state.scheduled_end_date_input,
                                                    maxLength: 10
                                                }}
                                                disabled={this.state.isDisabled}
                                                clearable={false}
                                                relativeYearFrom={0}
                                                relativeYearTo={3}
                                                dateDisabled={(data) =>
                                                    moment(data.date).isSame(moment(), 'day') && moment(data.date).isBefore() ? false : moment(data.date).isBefore() ? true : false
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="slds-form-element">
                                    <div className="slds-form-element__control timepickerselected scheduled_end_time" id="time_picker" onBlur={(e) => this.setValidatedTime(e, 'scheduled_end_time', true, 1)}>
                                        <Timepicker
                                            label={<span>&nbsp;End Time</span>}
                                            stepInMinutes={10}
                                            required={true}
                                            name="scheduled_end_time"
                                            id="scheduled_end_time"
                                            menuPosition="relative"
                                            strValue={this.state.scheduled_end_time}
                                            formatter={(date) => {
                                                if (date) {
                                                    return date.toLocaleTimeString(navigator.language, {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    });
                                                }
                                                return null;
                                            }}
                                            onDateChange={(date, inputStr) => {
                                                this.handleTimepicker(date, inputStr, 'scheduled_end_time');
                                                this.calcActiveDuration(1, "scheduled_end_time", inputStr);

                                            }}
                                        />
                                    </div>
                                    {this.state.showTimeErr['scheduled_end_time'] && <span style={{ color: "red" }}>{this.state.errMsg}</span>}
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Duration</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="scheduled_duration"
                                            disabled={true}
                                            placeholder="HH:MM"
                                            value={this.state.scheduled_duration || ''}
                                            data-rule-maxlength="6"
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.scheduled_rows.length > 0) ?
                            this.state.scheduled_rows.map((row, idx) => {
                                return (
                                    <div className="row py-2">
                                        <div className="col-sm-3">
                                            <label className="slds-form-element__label" htmlFor="select-01">
                                                <abbr class="slds-required" title="required">* </abbr>Break/Sleepover - {(idx + 1)}
                                            </label>
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control">
                                                    <SLDSReactSelect
                                                        simpleValue={true}
                                                        className={"SLDS_custom_Select default_validation slds-select scheduled_break_type_"+idx}
                                                        searchable={false}
                                                        placeholder="Please Select"
                                                        clearable={false}
                                                        required={true}
                                                        id={'scheduled_break_type_' + idx}
                                                        name={'scheduled_break_type_' + idx}
                                                        options={this.state.break_types}
                                                        onChange={(e) => {
                                                            handleShareholderNameChangeShift(this, 'scheduled_rows', idx, 'break_type', e)
                                                            this.setState({ changedValue: true });
                                                            //Reset line items
                                                            this.resetLineitems('scheduled');
                                                        }}
                                                        value={row.break_type}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control timepickerselected scheduled_break_start_time" onBlur={(e) => this.setBreakTime(e, 'scheduled_rows', 'break_start_time', idx, '', this)}>
                                                    <Timepicker
                                                        label={<span>&nbsp;Start Time</span>}
                                                        stepInMinutes={10}
                                                        required={false}
                                                        disabled={row.timing_disabled}
                                                        name={'sch_break_start_time_' + idx}
                                                        id={'sch_break_start_time_' + idx}
                                                        menuPosition="relative"
                                                        strValue={row.break_start_time}
                                                        formatter={(date) => {
                                                            if (date) {
                                                                return date.toLocaleTimeString(navigator.language, {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                });
                                                            }
                                                            return null;
                                                        }}
                                                        onDateChange={(date, inputStr) => {
                                                            handleShareholderNameChangeShift(this, 'scheduled_rows', idx, 'break_start_time', inputStr)
                                                            this.setState({ changedValue: true });
                                                            //Reset line items
                                                            this.resetLineitems('scheduled');
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control timepickerselected scheduled_break_end_time" onBlur={(e) => this.setBreakTime(e, 'scheduled_rows', 'break_end_time', idx, '', this)}>
                                                    <Timepicker
                                                        label={<span>&nbsp;End Time</span>}
                                                        stepInMinutes={10}
                                                        required={false}
                                                        disabled={row.timing_disabled}
                                                        name={'sch_break_end_time_' + idx}
                                                        id={'sch_break_end_time_' + idx}
                                                        menuPosition="relative"
                                                        strValue={row.break_end_time}
                                                        formatter={(date) => {
                                                            if (date) {
                                                                return date.toLocaleTimeString(navigator.language, {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                });
                                                            }
                                                            return null;
                                                        }}
                                                        onDateChange={(date, inputStr) => {
                                                            handleShareholderNameChangeShift(this, 'scheduled_rows', idx, 'break_end_time', inputStr)
                                                            this.setState({ changedValue: true });
                                                            //Reset line items
                                                            this.resetLineitems('scheduled');
                                                        }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" >Duration</label>
                                                <div className="slds-form-element__control">
                                                    <input type="text"
                                                        name="break_duration"
                                                        disabled={row.duration_disabled}
                                                        placeholder="HH:MM"
                                                        onChange={(e) => {
                                                            this.setState({ changedValue: true });
                                                            handleShareholderNameChangeShift(this, 'scheduled_rows', idx, 'break_duration', e.target.value)
                                                        }}
                                                        value={row.break_duration || ''}
                                                        data-rule-maxlength="6"
                                                        maxLength="6"
                                                        data-msg-number="Please enter valid number"
                                                        className="slds-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-1 pt-4">
                                            {idx >= 0 ? <Button
                                                category="reset"
                                                iconSize="large"
                                                iconName="delete"
                                                title="Remove break"
                                                variant="icon"
                                                iconClassName={`slds-button__icon_delete`}
                                                onClick={() => this.removeRow(idx)}
                                            /> : ''}
                                        </div>
                                    </div>
                                )
                            }) : ''}
                        <div className="row">
                            <div className="col-sm-9">&nbsp;</div>
                            <div className="col-sm-3">
                                <Button
                                    category="reset"
                                    iconSize="medium"
                                    iconName="new"
                                    label="Add a break"
                                    className="scheduled_break_btn"
                                    onClick={() => this.addRow()}
                                />
                            </div>
                        </div>
                        <label className="slds-form-element__label">Allowances and Reimbursements</label>
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Travel Allowance (KMs)</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="scheduled_travel"
                                            placeholder="KMs"
                                            onChange={(e) => {
                                                handleChange(this, e)
                                                this.setState({ changedValue: true });
                                            }}
                                            value={this.state.scheduled_travel || ''}
                                            data-rule-maxlength="6"
                                            maxLength="6"
                                            data-msg-number="Please enter valid number"
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Reimbursements ($)</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="scheduled_reimbursement"
                                            placeholder="Reimbursements (in $)"
                                            onChange={(e) => {
                                                this.setState({ changedValue: true });
                                                if (!isNaN(e.target.value)) {
                                                    handleDecimalChange(this, e);
                                                }
                                            }}
                                            maxLength="9"
                                            value={this.state.scheduled_reimbursement || ''}
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Commuting Travel Allowance (Distance KMs)</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="scheduled_travel_distance"
                                            placeholder="KMs"
                                            onChange={(e) => {
                                                var regx = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
                                                if (regx.test(e.target.value) === true || e.target.value === '') {
                                                    handleChange(this, e)
                                                    this.setState({ changedValue: true });
                                                }
                                            }}
                                            value={this.state.scheduled_travel_distance || ''}
                                            data-msg-number="Please enter valid number"
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Commuting Travel Allowance (Duration HH:MM)</label>
                                    <div className="slds-form-element__control">
                                        <div className="col-lg-3 col-sm-3">
                                            <input type="text"
                                                name="scheduled_travel_duration_hr"
                                                placeholder="HH"
                                                onChange={(e) => {
                                                    var regx = /^([0-1]?[0-9]|2[0-4])$/;
                                                    if (regx.test(e.target.value) === true || e.target.value === '') {
                                                        handleChange(this, e)
                                                        this.setState({ changedValue: true });
                                                    }
                                                }}

                                                onBlur={(e) => this.handleOnBlur(e, 'scheduled_travel_duration_hr')}
                                                value={this.state.scheduled_travel_duration_hr || ''}
                                                data-msg-number="Please enter valid number"
                                                className="slds-input" />

                                        </div>
                                        <div className="col-lg-1 col-sm-1" style={{ paddingTop: "7px" }}>:</div>
                                        <div className="col-lg-3 col-sm-3">
                                            <input type="text"
                                                name="scheduled_travel_duration_min"
                                                placeholder="MM"
                                                onChange={(e) => {
                                                    var regx = /^[0-5]?[0-9]$/;
                                                    if (regx.test(e.target.value) === true || e.target.value === '') {
                                                        handleChange(this, e)
                                                        this.setState({ changedValue: true });
                                                    }
                                                }}
                                                onBlur={(e) => this.handleOnBlur(e, 'scheduled_travel_duration_min')}
                                                value={this.state.scheduled_travel_duration_min || ''}
                                                data-msg-number="Please enter valid number"
                                                className="slds-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ExpandableSection>
            </React.Fragment>
        )
    }

    /**
     * Onchange handle line items
     */
    onChangeLineItem = (selection) => {
        this.setState({ sa_line_items: selection });
    }

    /**
     * Render NDIS
     */
    renderNDISPayment(index) {
        let account_person = this.state.account_person;
        let account_type = 0;
        let auto_insert_flag = false;
        let missing_items = [];
        let total_amount = 0;
        if (account_person && account_person.account_type) {
            account_type = account_person.account_type;
        }
        let is_ndis = this.state.is_ndis;
        if (Number(account_type) !== 1 || !is_ndis) {
            return <React.Fragment />
        }
        let docusign_id = this.state[index + '_docusign_id'];
        let docusign_url = this.state[index + '_docusign_url'];
        let docusign_related = this.state[index + '_docusign_related'];
        let support_type = this.state[index + '_support_type'];
        let ndis_line_item_list = this.state[index + '_ndis_line_item_list'];

        return (
            <React.Fragment>
                <ExpandableSection
                    id={`"ndis-payments-${index}`}
                    title="NDIS Payments"
                >
                    <div className="container-fluid">
                        <div className="row py-2">
                            <div className="col-sm-3">
                                <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                    Service Agreement</label>
                            </div>
                            <div className="col-sm-9">
                                {
                                    docusign_id ?
                                        <a style={{ color: '#0070d2' }} className="reset" title="View/download contract" target="_blank" href={docusign_url}>{docusign_related}</a>
                                        :
                                        <   Link className="slds-truncate" to={'#'}>No active plan</Link>
                                }
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                        <abbr class="slds-required" title="required">* </abbr> Support Type</label>
                                    <div className="slds-form-element__control">
                                        <div className="SLDS_date_picker_width">
                                            <SLDSReactSelect
                                                disabled={index === 'actual' ? true : false}
                                                simpleValue={true}
                                                className="custom_select default_validation"
                                                options={this.state.support_type_options}
                                                onChange={(value) => {
                                                    this.handleChange(value, 'support_type', index)
                                                    this.setState({ changedValue: true });
                                                }}
                                                value={support_type || ''}
                                                clearable={false}
                                                searchable={false}
                                                placeholder="Please Select"
                                                name={index + "_support_type"}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                        Line Item</label>
                                    <div className="slds-form-element__control">
                                        <div className={index + "_ndis_line_item_list ndis_line_item-shift p-1"}>
                                            {(ndis_line_item_list.length > 0) ?

                                            ndis_line_item_list.map((item_value, in_val) => {
                                                auto_insert_flag = (item_value.auto_insert_flag === true || item_value.auto_insert_flag === "1") ? true : auto_insert_flag;
                                                if (item_value.auto_insert_flag === true || item_value.auto_insert_flag === "1") {
                                                    missing_items.push(<p className="slds-p-bottom_x-small">{item_value.line_item_value}</p>);
                                                }
                                                if (item_value.sub_total != null) {    
                                                    //If amount has comma then remove and then add value                                                
                                                    total_amount = total_amount + parseFloat(item_value.sub_total);
                                                }                                                
                                                return (
                                                    <div className="row mb-1">
                                                        <div className="col-sm-1">
                                                            <Icon
                                                                assistiveText="Account"
                                                                category="utility"
                                                                name="file"
                                                                size="xx-small"
                                                            />
                                                        </div>
                                                        <div className="col-sm-10">
                                                            <p className={index+"_ndis_line_item_"+in_val}>
                                                                {item_value.line_item_value}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    )
                                                }) : ''
                                            }

                                        </div>
                                    </div>
                                    {auto_insert_flag === true ?
                                        (<div className="slds-form-element__label">
                                            <Popover
                                                align="top left"
                                                body={
                                                    <div>
                                                        {missing_items}
                                                    </div>
                                                }
                                                heading="Missing Support items"
                                                id="popover-error"
                                                variant="error"
                                                className="slds-cus-popover-heading"
                                                {...this.props}
                                            >
                                                <Button
                                                    assistiveText={{ icon: 'Icon Info' }}
                                                    iconCategory="utility"
                                                    iconName="info"
                                                    iconSize="small"
                                                    iconVariant="bare"
                                                    variant="icon"
                                                />
                                            </Popover>
                                            <span> One (or) more support items listed here are not found in plan</span>
                                        </div>)
                                        : <React.Fragment />}
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-sm-6">
                            </div>
                            <div className="col-sm-6">
                                <div className="row py-2">
                                    <div className="col-sm-3 pt-1">
                                        <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                            Shift Cost ($)</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <input
                                            className="slds-input ndis-shift-cost"
                                            placeholder="0.00"
                                            type="text"
                                            name="shift_cost"
                                            maxLength={10}
                                            value={total_amount.toFixed(2)}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ExpandableSection>
            </React.Fragment>
        );
    }

    /**
     * rendering contact information
     */
    RenderContactInformation() {
        return (
            <React.Fragment>
                <ExpandableSection
                    id="default-expandable-section"
                    title="Confirmation Details"
                >
                    <div className="container-fluid">
                        <div className="row py-2">
                            <div className="col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1"><abbr class="slds-required" title="required">* </abbr>
                                        Phone</label>
                                    <div className="slds-form-element__control">
                                        <div className="SLDS_date_picker_width">
                                            <input
                                                className="slds-input"
                                                placeholder="Phone"
                                                required={true}
                                                type="text"
                                                name="contact_phone"
                                                maxLength={18}
                                                data-rule-phonenumber={true}
                                                data-msg-valid_ndis="Please enter a valid phone number"
                                                value={this.state.contact_phone || ""}
                                                onChange={e => {
                                                    handleChange(this, e)
                                                    this.setState({ changedValue: true });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1"><abbr class="slds-required" title="required">* </abbr>
                                        Email</label>
                                    <div className="slds-form-element__control">
                                        <div className="SLDS_date_picker_width">
                                            <input
                                                className="slds-input"
                                                placeholder="Email"
                                                type="text"
                                                name="contact_email"
                                                maxLength={100}
                                                required={true}
                                                data-rule-email="true"
                                                value={this.state.contact_email || ""}
                                                onChange={e => {
                                                    this.setState({ changedValue: true });
                                                    handleChange(this, e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ExpandableSection>
            </React.Fragment>
        );
    }

    /**
     * rendering actual timing part of the schedule form
     */
    RenderActualSection() {
        return (
            <React.Fragment>
                <ExpandableSection
                    id="default-expandable-section"
                    title="Actual Times"
                >
                    <div className="container-fluid">
                        <div className="row py-2">
                            <div className="col-sm-3">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                        Actual Start Date</label>
                                    <div className="slds-form-element__control">
                                        <div className="SLDS_date_picker_width" onKeyDown={(e) => {
                                            if (e.key === 'Tab') {
                                                const { current } = this.datepickers['actual_start_date'] || {}
                                                if (current && 'instanceRef' in current) {
                                                    current.instanceRef.setState({ isOpen: false })
                                                }
                                            }
                                        }}>
                                            <SLDSISODatePicker
                                                type="date"
                                                ref={this.datepickers.actual_start_date} // !important: this is needed by this custom SLDSISODatePicker
                                                className="date_picker"
                                                placeholder="DD/MM/YYYY"
                                                onChange={this.handleChangeDatePicker('actual_start_date')}
                                                onOpen={this.handleDatePickerOpened('actual_start_date')}
                                                onClear={this.handleChangeDatePicker('actual_start_date')}
                                                value={this.state.actual_start_date}
                                                input={<Input name="actual_start_date" />}
                                                inputProps={{
                                                    name: "actual_start_date",
                                                    value: this.state.actual_start_date_input,
                                                    maxLength: 10
                                                }}
                                                clearable={false}
                                                relativeYearFrom={0}
                                                relativeYearTo={3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="slds-form-element">
                                    <div className="slds-form-element__control timepickerselected actual_break_start_time" id="actual_start_time" onBlur={(e) => this.setValidatedTime(e, 'actual_start_time', true, 2)}>
                                        <Timepicker
                                            label={<span>Start Time</span>}
                                            stepInMinutes={10}
                                            name="actual_start_time"
                                            id="actual_start_time"
                                            menuPosition="relative"
                                            strValue={this.state.actual_start_time}
                                            onDateChange={(date, inputStr) => {
                                                this.handleTimepicker(date, inputStr, 'actual_start_time');
                                                this.calcActiveDuration(2, "actual_start_time", inputStr);
                                            }}
                                        />
                                    </div>
                                    {this.state.showTimeErr['actual_start_time'] && <span style={{ color: "red" }}>{this.state.errMsg}</span>}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="text-input-id-1">Actual End Date</label>
                                    <div className="slds-form-element__control">
                                        <div className="SLDS_date_picker_width" onKeyDown={(e) => {
                                            if (e.key === 'Tab') {
                                                const { current } = this.datepickers['actual_end_date'] || {}
                                                if (current && 'instanceRef' in current) {
                                                    current.instanceRef.setState({ isOpen: false })
                                                }
                                            }
                                        }}>
                                            <SLDSISODatePicker
                                                type="date"
                                                ref={this.datepickers.actual_end_date} // !important: this is needed by this custom SLDSISODatePicker
                                                className="date_picker"
                                                placeholder="DD/MM/YYYY"
                                                onChange={this.handleChangeDatePicker('actual_end_date')}
                                                onOpen={this.handleDatePickerOpened('actual_end_date')}
                                                onClear={this.handleChangeDatePicker('actual_end_date')}
                                                value={this.state.actual_end_date}
                                                input={<Input name="actual_end_date" />}
                                                inputProps={{
                                                    name: "actual_end_date",
                                                    value: this.state.actual_end_date_input,
                                                    maxLength: 10
                                                }}
                                                clearable={false}
                                                relativeYearFrom={0}
                                                relativeYearTo={3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="slds-form-element">
                                    <div className="slds-form-element__control timepickerselected actual_break_end_time" id="actual_end_time" onBlur={(e) => this.setValidatedTime(e, 'actual_end_time', true, 2)}>
                                        <Timepicker
                                            label={<span>End Time</span>}
                                            stepInMinutes={10}
                                            name="actual_end_time"
                                            id="actual_end_time"
                                            menuPosition="relative"
                                            strValue={this.state.actual_end_time}
                                            onDateChange={(date, inputStr) => {
                                                this.handleTimepicker(date, inputStr, 'actual_end_time');
                                                this.calcActiveDuration(2, "actual_end_time", inputStr);

                                            }}
                                        />
                                    </div>
                                    {this.state.showTimeErr['actual_end_time'] && <span style={{ color: "red" }}>{this.state.errMsg}</span>}
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Duration</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="actual_duration"
                                            disabled={true}
                                            placeholder="HH:MM"
                                            value={this.state.actual_duration || ''}
                                            data-rule-maxlength="6"
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.actual_rows.length > 0) ?
                            this.state.actual_rows.map((row, idx) => {
                                var break_type_val = row.break_type;
                                return (
                                    <div className="row py-2">
                                        <div className="col-sm-3">
                                            <label className="slds-form-element__label" htmlFor="select-01">
                                                Break/Sleepover - {(idx+1)}
                                            </label>
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control">
                                                    <SLDSReactSelect
                                                        simpleValue={true}
                                                        className="SLDS_custom_Select default_validation slds-select"
                                                        searchable={false}
                                                        placeholder="Please Select"
                                                        clearable={false}
                                                        required={true}
                                                        id={'break_type_' + idx}
                                                        options={this.state.break_types}
                                                        onChange={(e) => handleShareholderNameChangeShift(this, 'actual_rows', idx, 'break_type', e)}
                                                        value={row.break_type}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control timepickerselected"  onBlur={(e)=> this.setBreakTime(e,'actual_rows','break_start_time',idx, '', this)}>
                                                    <Timepicker
                                                        label={<span>&nbsp;Start Time</span>}
                                                        stepInMinutes={10}
                                                        name={"act_break_start_time"+ idx}
                                                        id={"act_break_start_time"+ idx}
                                                        disabled={row.timing_disabled}
                                                        menuPosition="relative"
                                                        strValue={row.break_start_time}
                                                        onDateChange={(date, inputStr) => {
                                                            this.setState({ changedValue: true });
                                                            handleShareholderNameChangeShift(this, 'actual_rows', idx, 'break_start_time', inputStr)
                                                            this.resetLineitems('actual');
                                                            this.get_service_agreement(true, 'actual_rows');
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="slds-form-element">
                                                <div className="slds-form-element__control timepickerselected" onBlur={(e)=> this.setBreakTime(e,'actual_rows','break_end_time',idx, '', this)}>
                                                    <Timepicker
                                                        label={<span>&nbsp;End Time</span>}
                                                        stepInMinutes={10}
                                                        disabled={row.timing_disabled}
                                                        name={"act_break_end_time"+ idx}
                                                        id={"act_break_end_time"+ idx}
                                                        menuPosition="relative"
                                                        strValue={row.break_end_time}
                                                        onDateChange={(date, inputStr) => {
                                                            handleShareholderNameChangeShift(this, 'actual_rows', idx, 'break_end_time', inputStr)
                                                            this.resetLineitems('actual');
                                                            this.setState({ changedValue: true });
                                                            this.get_service_agreement(true, 'actual_rows');
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" >Duration</label>
                                                <div className="slds-form-element__control">
                                                    <input type="text"
                                                    name="break_duration"
                                                    placeholder="HH:MM"
                                                    disabled={row.duration_disabled}
                                                    onChange={(e) => {
                                                        handleShareholderNameChangeShift(this, 'actual_rows', idx, 'break_duration', e.target.value)
                                                        this.setState({ changedValue: true });
                                                    }}
                                                    value={row.break_duration || ''}
                                                    data-msg-number="Please enter valid number"
                                                    className="slds-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-1 pt-4">
                                            {idx >= 0 ? <Button
                                                category="reset"
                                                iconSize="large"
                                                iconName="delete"
                                                title="Remove break"
                                                variant="icon"
                                                iconClassName={`slds-button__icon_delete`}
                                                onClick={() => this.removeRow(idx, true)}
                                            /> : ''}
                                        </div>
                                    </div>
                                )
                            }) : ''}
                        <div className="row">
                            <div className="col-sm-9">&nbsp;</div>
                            <div className="col-sm-3">
                                <Button
                                    category="reset"
                                    iconSize="medium"
                                    iconName="new"
                                    label="Add a break"
                                    id="actual_break_btn"
                                    onClick={() => this.addRow(true)}
                                />
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Travel (KMs)</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="actual_travel"
                                            placeholder="KMs"
                                            onChange={(e) => {
                                                handleChange(this, e)
                                                this.setState({ changedValue: true });
                                            }}
                                            value={this.state.actual_travel || ''}
                                            data-rule-maxlength="6"
                                            maxLength="6"
                                            data-msg-number="Please enter valid number"
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Reimbursements ($)</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="actual_reimbursement"
                                            placeholder="Reimbursements (in $)"
                                            onChange={(e) => {
                                                this.setState({ changedValue: true });
                                                if (!isNaN(e.target.value)) {
                                                    handleDecimalChange(this, e);
                                                }
                                            }}
                                            maxLength="9"
                                            value={this.state.actual_reimbursement || ''}
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Commuting Travel Allowance (Distance KMs)</label>
                                    <div className="slds-form-element__control">
                                        <input type="text"
                                            name="actual_travel_distance"
                                            placeholder="KMs"
                                            onChange={(e) => {
                                                var regx = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
                                                if (regx.test(e.target.value) === true || e.target.value === '') {
                                                    handleChange(this, e)
                                                    this.setState({ changedValue: true });
                                                }
                                            }}
                                            value={this.state.actual_travel_distance || ''}
                                            data-msg-number="Please enter valid number"
                                            className="slds-input" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" >Commuting Travel Allowance (Duration HH:MM)</label>
                                    <div className="slds-form-element__control">
                                        <div className="col-lg-3 col-sm-3">
                                            <input type="text"
                                                name="actual_travel_duration_hr"
                                                placeholder="HH"
                                                onChange={(e) => {
                                                    var regx = /^([0-1]?[0-9]|2[0-4])$/;

                                                    if (regx.test(e.target.value) === true || e.target.value === '') {
                                                        handleChange(this, e)
                                                        this.setState({ changedValue: true });
                                                    }
                                                }}

                                                onBlur={(e) => this.handleOnBlur(e, 'actual_travel_duration_hr')}
                                                value={this.state.actual_travel_duration_hr || ''}
                                                data-msg-number="Please enter valid number"
                                                className="slds-input" />

                                        </div>
                                        <div className="col-lg-1 col-sm-1" style={{ paddingTop: "7px" }}>:</div>
                                        <div className="col-lg-3 col-sm-3">
                                            <input type="text"
                                                name="actual_travel_duration_min"
                                                placeholder="MM"
                                                onChange={(e) => {
                                                    var regx = /^[0-5]?[0-9]$/;
                                                    if (regx.test(e.target.value) === true || e.target.value === '') {
                                                        handleChange(this, e)
                                                        this.setState({ changedValue: true });
                                                    }
                                                }}
                                                onBlur={(e) => this.handleOnBlur(e, 'actual_travel_duration_min')}
                                                value={this.state.actual_travel_duration_min || ''}
                                                data-msg-number="Please enter valid number"
                                                className="slds-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-lg-6 col-sm-6">
                                <div className="slds-form-element">
                                    <label className="slds-form-element__label" htmlFor="select-01">Notes</label>
                                    <div className="slds-form-element__control">
                                        <textarea
                                            className="slds-textarea"
                                            name="notes"
                                            placeholder="Notes"
                                            onChange={(e) => {
                                                this.setState({ changedValue: true });
                                                this.handleChange(e.target.value, "notes")
                                            }}
                                            value={this.state.notes ? this.state.notes : ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ExpandableSection>
            </React.Fragment>
        )
    }

    /**
     * toggling repeat days modal
     */
    toggleRepeatModal = (save) => {
        this.setState({ repeat_open: !this.state.repeat_open });
        if (save) {
            this.onSubmit();
        }
    };

    /**
     * when the repeat option is selected then creating the days list for selection
     */
    addDaysSelection = (repeat_option) => {
        var dateYmdHis = this.state.scheduled_start_date;
        if (moment(moment(dateYmdHis).format('DD/MM/YYYY'), 'DD/MM/YYYY', true).isValid() == true) {
            var today = moment();
            var start_date = moment(dateYmdHis);
            var repeat_days = [];
            var nextDay = '';

            // repeating on selected days
            if (repeat_option == 3) {
                // starting from first day of week
                var week_start = moment(dateYmdHis).subtract(1, 'days').startOf('week').weekday(1);
                var start_date_format = moment(start_date.format('YYYY-MM-DD'));

                var date_status = 0;
                if (start_date_format.isAfter(week_start)) {
                    date_status = 1; // past day
                }
                else if (start_date_format.isSame(week_start)) {
                    date_status = 2; // same day
                }
                else if (start_date_format.isBefore(week_start)) {
                    date_status = 3; // future day
                }
                var day = { date_status: date_status, format_date: week_start.format('dddd'), actual_date: week_start.format('YYYY-MM-DD') };
                repeat_days.push(day);

                // adding remaining 6 days of the week, including the currently selected start date
                for (var i = 1; i < 7; i++) {
                    nextDay = moment(week_start).add(i, 'days');
                    if (start_date_format.isAfter(nextDay)) {
                        date_status = 1; // past day
                    }
                    else if (start_date_format.isSame(nextDay)) {
                        date_status = 2; // same day
                    }
                    else if (start_date_format.isBefore(nextDay)) {
                        date_status = 3; // future day
                    }

                    day = { date_status: date_status, format_date: nextDay.format('dddd'), actual_date: nextDay.format('YYYY-MM-DD') };
                    repeat_days.push(day);
                }

                // remove if selected date is equal to start date
                var start_date_act = start_date.format('YYYY-MM-DD');
                var repeat_days_selected = this.state.repeat_days_selected;
                if (repeat_days_selected) {
                    repeat_days_selected.splice(repeat_days_selected.indexOf(start_date_act), 1);
                    this.setState({ repeat_days_selected: repeat_days_selected });
                }
            }

            this.setState({ repeat_days: repeat_days });
        }
    }

    /**
     * rendering repeat modal of the schedule form
     */
    showRepeatModal() {

        return (
            <React.Fragment>
                <Modal
                    size="small"
                    heading={"Repeat Shift"}
                    isOpen={this.state.repeat_open}
                    footer={[
                        <Button disabled={this.state.loading} label="Cancel" onClick={() => this.toggleRepeatModal()} />,
                        <Button disabled={this.state.loading} label="Save" variant="brand" onClick={() => this.toggleRepeatModal(true)} />]}
                    onRequestClose={this.toggleRepeatModal}>
                    <div className="container">
                        <div className="row py-2">
                            <div className="col-sm-3">
                                <div className="slds-form-element pb-2">
                                    <label className="slds-form-element__label" >Select the days to repeat the shift:</label>
                                </div>
                                <div class="slds-form-element__control" id="date_hide">
                                    <SLDSISODatePicker
                                        menuPosition={'relative'}
                                        ref={this.datepickers.repeat_hidden_date} // !important: this is needed by this custom SLDSISODatePicker
                                        className="date_hide"
                                        placeholder="Start Date"
                                        isOpen={true}
                                        onChange={this.handleChangeDatePicker('repeat_hidden_date')}
                                        onOpen={this.handleDatePickerOpened('repeat_hidden_date')}
                                        onClear={this.handleChangeDatePicker('repeat_hidden_date')}
                                        disableOnClickOutside={true}
                                        dateDisabled={(data) => {
                                            const { date } = data
                                            const dateYmd = moment(date).format('YYYY-MM-DD')
                                            const todayYmd = moment().format('YYYY-MM-DD')
                                            const isAfter = moment(dateYmd).isBefore(todayYmd)
                                            return isAfter
                                        }}

                                        relativeYearFrom={0}
                                        relativeYearTo={3}
                                        value={this.state.scheduled_start_date}
                                        clearable
                                    />
                                </div>
                            </div>
                            <div className="col-sm-9 pl-5">
                                <div className="slds-form-element pb-2">
                                    <label className="slds-form-element__label">Selected Days:</label>
                                </div>
                                <div class="slds-form-element__control date_clip">
                                    {(this.state.repeat_days_selected.length > 0) ? this.state.repeat_days_selected.sort().map((comdate, idx) => {
                                        return <div><Button
                                            category="reset"
                                            iconSize="medium"
                                            iconName="delete"
                                            variant="icon"
                                            iconClassName={`slds-button__icon_delete`}
                                            onClick={() => this.addRemoveSelectedRepeatDates(comdate)}
                                        />&nbsp; {moment(comdate).format("dddd, DD/MM/YYYY")}</div>
                                    }) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }

    /**
     * Handle onchange repeat option
     * @param {obj} e
     */
    onChangeRepeatOption = (e) => {
        this.state.repeat_option = e.value;
        let selected_val = Number(e.value);

        let roster_id = this.state.roster_id;
        let roster = this.state.roster_options.filter((item, key) => item.value === roster_id).map(({ label, value, start_date, end_date }) => ({ label, value, start_date, end_date }));
        let selected_roster = roster[0] ? roster[0] : '';
        if (selected_roster.start_date && selected_roster.end_date) {
            let start_date_ros = moment(selected_roster.start_date);
            let end_date_ros = moment(selected_roster.end_date);
            let start_date = moment(this.state.scheduled_start_date);
            let end_date = moment(this.state.scheduled_end_date);
            if (start_date_ros.isBefore(start_date) === false) {
                this.toastmsg("Schedule start date must be greater than roster start date", 'e');
                return;
            }
            if (end_date_ros.isAfter(start_date) === false) {
                this.toastmsg("Schedule start date must be less than roster end date", 'e');
                return;
            }
            if (end_date_ros.isAfter(end_date) === false) {
                this.toastmsg("Schedule end date must be less than roster end date", 'e');
                return;
            }
            if (start_date_ros.isBefore(end_date) === false) {
                this.toastmsg("Schedule end date must be greater than roster start date", 'e');
                return;
            }
        }
        this.setState({ selected_roster: roster });
        switch (selected_val) {
            case 3:
                this.addDaysSelection(e.value);
                this.setState({ repeat_open: true })
                break;
            case 4:
            case 5:
            case 6:
                this.setState({ openRosterRepeatModal: true });
                break;
            default:
                this.onSubmit();
                break;
        }
    }

    /**
     * Get all months between two dates
     * @param {str} startDate
     * @param {str} endDate
     * @returns
     */
     dateRange = (startDate, endDate) => {
        startDate = moment(startDate).format('YYYY-MM-DD');
        endDate = moment(endDate).format('YYYY-MM-DD');
        var start      = startDate.split('-');
        var end        = endDate.split('-');
        var startYear  = parseInt(start[0]);
        var endYear    = parseInt(end[0]);
        var dates      = [];

        for(var i = startYear; i <= endYear; i++) {
          var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
          var startMon = i === startYear ? parseInt(start[1])-1 : 0;
          for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
            var month = j+1;
            var displayMonth = month < 10 ? '0'+month : month;
            dates.push([i, displayMonth, '01'].join('-'));
          }
        }
        return dates;
    }

    /**
     * Calculate the week between two date
     * @param {date} dt1
     * @param {date} dt2
     * @returns
     */
    diff_weeks(dt2, dt1) {

        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7);
        return Math.abs(Math.round(diff));

    }

    /**
     * Save Repeat roster
     * @param {array} day_selected
     */
    saveRepeatRoster = (day_selected, repeat_specific_days) => {
        this.setState({ repeat_days_selected: day_selected, openRosterRepeatModal: false, repeat_specific_days }, () => {
            this.onSubmit();
        });
    }

    /**
     * rendering components
     */
    render() {
        let repeatOptions = [
            { label: 'Repeat for tomorrow', value: '1' },
            { label: 'Repeat for rest of the week', value: '2' },
            { label: 'Repeat for specific days', value: '3' },
        ];
        if (this.state.roster_id) {
            repeatOptions = [
                { label: 'Repeat for tomorrow', value: '1' },
                { label: 'Repeat for rest of the week', value: '2' },
                { label: 'Repeat for specific days', value: '3' }
            ];
            if (this.state.week_count >= 1) {
                var week = [];
                week['label'] = 'Repeat weekly';
                week['value'] = '4';
                repeatOptions.push(week);
            }
            if (this.state.weekfort_count > 1) {
                var week_fornight = [];
                week_fornight['label'] = 'Repeat fortnightly';
                week_fornight['value'] = '5';
                repeatOptions.push(week_fornight);
            }
            if (this.state.month_count > 1) {
                var month = [];
                month['label'] = 'Repeat monthily';
                month['value'] = '6';
                repeatOptions.push(month);
            }

        }
        return (
            <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                <div>
                    <button id="btn-datepicker" className="d-none">click</button>
                    <Modal
                        isOpen={this.props.showModal}
                        footer={[
                            <Button disabled={this.state.loading} label="Cancel" onClick={() => this.props.closeModal(false)} />,
                            this.props.id > 0 ? <Button disabled={this.state.loading} label="Save" variant="brand" onClick={this.onSubmit} /> : <ButtonGroup variant="" id="button-group-footer">
                                <Button disabled={this.state.loading} label="Save" variant="brand" onClick={this.onSubmit} />
                                <Dropdown
                                    assistiveText={{ icon: 'More Options' }}
                                    iconCategory="utility"
                                    iconName="down"
                                    align="right"
                                    iconSize="x-medium"
                                    iconVariant="brand"
                                    onSelect={(e) => {
                                        this.onChangeRepeatOption(e);
                                    }}
                                    width="xx-small"
                                    options={repeatOptions}
                                />
                            </ButtonGroup>
                        ]}
                        onRequestClose={this.toggleOpen}
                        heading={this.props.id ? "Update Shift" : "Create New Shift"}
                        size="small"
                        className="slds_custom_modal add_edit_shift_modal"
                        id="add_edit_shift"
                        onRequestClose={() => this.props.closeModal(false)}
                        dismissOnClickOutside={false}
                    >

                        <section className="manage_top" >

                            <form id="create_shift" autoComplete="off" className="slds_form">
                                {this.RenderGeneralSection()}
                                {this.RenderContactInformation()}
                                {this.RenderScheduleSection()}
                                {/* {this.renderNDISPayment('scheduled')} */}
                                {/* {this.RenderActualSection()} */}
                                {/* {this.renderNDISPayment('actual')} */}
                                {this.showRepeatModal()}
                            </form>
                        </section>

                        {/* {
                            this.state.openCreateContactModal && (
                                <CreateContactModal
                                    contactId=''
                                    showModal={this.state.openCreateContactModal}
                                    closeModal={this.closeContactModal}
                                    is_manage_contact_role={true}
                                    account_person={this.state.account_person}
                                />
                            )
                        } */}
                        {/* {
                            this.state.openRosterRepeatModal && (
                                <RosterRepeatModal
                                    showModal={this.state.openRosterRepeatModal}
                                    closeModal={this.closeRosterRepeatModal}
                                    saveRepeatRoster={this.saveRepeatRoster}
                                    roster_id={this.state.roster_id}
                                    headingTxt={'Repeat Shift'}
                                    roster={this.state.selected_roster}
                                    scheduled_start_date={this.state.scheduled_start_date}
                                    scheduled_end_date={this.state.scheduled_end_date}
                                    repeat_option={this.state.repeat_option}
                                />
                            )
                        } */}
                    </Modal>
                </div>
            </IconSettings>
        );
    }
}

export default CreateShiftModal;
