import React from 'react'
import PropTypes from 'prop-types';
import {ARF} from "../services/ARF";
/**
 * Provide label for input field - import Label from '@oncallui/react-framework/input/Label'
 */
const Label = function(props) {
    let _this = {
        required: props.required || false,
        text: props.text || '',
        id: ARF.uniqid(props, 'label')
    }
    return (   
        (_this.text || props.children) &&
        (<label style={{...props.style} || ""} id={_this.id} className="slds-form-element__label" >
            {_this.required && <abbr className="slds-required" title="required">* </abbr>}
            {_this.text}
            {props.children}
        </label>) || ""
    )
}
Label.propTypes = {
    /**
     * Set to "true" if filed is required, default is optional
     */
    required: PropTypes.bool
}
export default Label