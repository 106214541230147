import React, { Component } from 'react';
import jQuery from "jquery";
import ReactGoogleAutocomplete from 'components/externl_component/ReactGoogleAutocomplete';
import 'react-select-plus/dist/react-select-plus.css';

import { checkItsNotLoggedIn, postData, toastMessageShow, css, handleChange, queryOptionData } from 'service/common.js';
import { ExpandableSection } from '@salesforce/design-system-react';
import 'react-block-ui/style.css';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';

import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import 'service/jquery.validate.js';
import "service/custom_script.js";
import SLDSReactSelect from 'components/salesforce/lightning/SLDSReactSelect.jsx';


/**
 * based on the keyword, searching and listing the parent account options
 */
const getOptionOfParantAccount = (e) => {
    if (!e) {
        return Promise.resolve({ options: [] });
    }

    return postData("sales/Account/get_option_of_account", { search: e }).then(json => {
        if (json.status) {
            return { options: json.data };
        } else {
            return { options: [] };
        }

    });
}

const styles = css({
    root: {
        fontFamily: `Salesforce Sans, Arial, Helvetica, sans-serif`,
        zIndex: 12,
    },
    backdrop: {
        zIndex: 11,
    },
})


class CreateSiteModel extends Component {
    constructor(props) {
        super(props);
        checkItsNotLoggedIn();

        this.state = {
            loading: false,
            redirectPage: false,
            parent_org: this.props.parent_org,
            is_site: '',
            cost_book_id: null,
            status: 1,
            role_options: [],
            cost_book_options: [],
            valid_abn: "pending",
            payable_email: '',
            payable_phone: '',
            org_service_type_option: [],
            org_service_type: '',
            account_name: '',
            role_id: ''
        }
    }

    /**
     * when ABN/ACN is searched
     */
    onCHangeAbnAcnNumber = (e) => {
        var value = e.target.value.trim()

        if (e != undefined && e.target.pattern) {
            const re = eval(e.target.pattern);
            if (e.target.value != "" && !re.test(e.target.value)) {
                return;
            }
        }

        this.setState({ abn: value }, () => {
            if (value === "") {
                this.setState({ valid_abn: "pending" });
                return false;
            }
            var search = false;

            if (this.state.abn.length == 9) {
                search = true;
            } else if (this.state.abn.length == 11) {
                search = true;
            } else {
                this.setState({ valid_abn: false });
            }

            if (this.state.requestedMethod) {
                this.state.requestedMethod.abort();
                this.setState({ search_abn_num: false })
            }


            if (search) {
                this.setState({ search_abn_num: true })
                postData("sales/Account/get_abn_acn_number_on_base_search", { search: this.state.abn }, this).then(result => {
                    if (result.status) {
                        this.setState({ valid_abn: true, account_name: result.data.account_name });
                    } else {
                        this.setState({ valid_abn: false, account_name: '' });
                    }
                    this.setState({ search_abn_num: false })
                });
            }
        })
    }

    /**
     * when the org/account name is selected
     */
    onChangeAccountName = (e) => {
        this.setState({ abn: '', account_name: e.target.value });
    }

    /**
     * when the add/edit form is submitted
     */
    onSubmit = (e) => {
        e.preventDefault();
        jQuery("#create_org").validate({ /* */ });
        this.setState({ validation_calls: true })

        if (this.state.valid_abn === false) {
            toastMessageShow("Please provide valid ABN/ACN number", "e");
            return;
        }

        if (!this.state.loading && jQuery("#create_org").valid()) {
            this.setState({ loading: true });

            var req = { ...this.state }
            postData('sales/Account/create_organisation', req).then((result) => {
                if (result.status) {
                    let msg = result.hasOwnProperty('msg') ? result.msg : '';
                    toastMessageShow(result.msg, 's');
                    this.props.closeModal(true);
                } else {
                    toastMessageShow(result.error, "e");
                }
                this.setState({ loading: false });
            });
        }
    }

    /**
     * when billing address is asked to be same as shipping
     */
    sameAsShippingAddress = (e) => {
        if (e.target.checked) {
            this.setState({ billing_same_as_shipping: true, billing_address: this.state.shipping_address, billing_unit_number: this.state.shipping_unit_number });
        } else {
            this.setState({ billing_same_as_shipping: false, billing_address: '', billing_unit_number: '' });
        }
    }

    /**
     * fetching the active roles/service types list
     */
    get_next_site_no() {
        postData('sales/Account/get_next_site_no', { id: this.props.childorgprops.id }).then((result) => {
            if (result.status) {
                this.setState({ account_name: this.props.childorgprops.account_name + ' - Site ' + result.data });
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    /**
     * fetching the active roles/service types list
     */
    get_role_list() {
        postData('item/MemberRole/get_active_role_list').then((result) => {
            if (result.status) {
                this.setState({ role_options: result.data });
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    /**
     * fetching the active cost book options list
     */
    get_cost_book_options() {
        postData('common/get_cost_book_options').then((result) => {
            if (result.status) {
                this.setState({ cost_book_options: result.data });
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        this.get_role_list();
        this.getOptionsOrganisationSource();
        this.getOptionsOrganisationServiceType();
        this.get_cost_book_options();
        this.setState({ is_site: this.props.add_site });
        if (this.props.add_site == 1) {
            this.get_next_site_no();
        }

        if (this.props.org_id) {
            this.get_organisationDetails(this.props.org_id);
        }
        if (this.props.childorgprops && this.props.childorgprops.id) {
            this.setState({ parent_org: { "label": this.props.childorgprops.account_name, "value": this.props.childorgprops.id } });

        }
    }

    /**
     * fetching the list of organisation source
     */
    getOptionsOrganisationSource = () => {
        queryOptionData(1, "sales/Account/get_organization_source").then((res) => { this.setState({ org_type_option: res.options }) });
    }

    /**
    *
    * @param {*} org_id
    */
    getOptionsOrganisationServiceType = () => {
        queryOptionData(1, "sales/Account/get_organization_service_type").then((res) => { this.setState({ org_service_type_option: res.options }) });
    }

    /**
     * fetching the organisation details
     */
    get_organisationDetails = (org_id) => {
        postData('sales/Account/get_organisation_details', { org_id }).then((result) => {
            if (result.status) {
                this.setState(result.data);
            } else {
                toastMessageShow(result.error, "e");
            }

        });
    }

    /**
     * rendering account information
     */
    renderAccountInformation() {
        return (
            <ExpandableSection id="default-expandable-section" title="Account Information">
                <div className="container-fluid">                   

                    <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                    <abbr className="slds-required" title="required">* </abbr>Account Name</label>
                                <div className="slds-form-element__control">
                                    <input type="text"
                                        className="slds-input"
                                        type="text"
                                        name="account_name"
                                        placeholder="Account Name"
                                        disabled={this.state.valid_abn === true ? true : false}
                                        onChange={(e) => this.onChangeAccountName(e)}
                                        value={this.state.account_name || ''}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01">{this.state.is_site == 1 ? <abbr className="slds-required" title="required">* </abbr> : ''}Parent Account</label>
                                <div className="slds-form-element__control">
                                    <SLDSReactSelect.Async clearable={false}
                                        className="SLDS_custom_Select default_validation"
                                        value={this.state.parent_org}
                                        cache={false}
                                        required={this.state.is_site == 1 ? true : false}
                                        loadOptions={(val) => getOptionOfParantAccount(val)}
                                        onChange={(evt) => this.setState({ "parent_org": evt })}
                                        placeholder="Please Search"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row py-2">
                    <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                    <abbr className="slds-required" title="required">* </abbr>Service Type</label>
                                <div className="slds-form-element__control">
                                    <SLDSReactSelect
                                        name="role_id"
                                        className="SLDS_custom_Select default_validation"
                                        simpleValue={true}
                                        searchable={true}
                                        placeholder="Please Select"
                                        clearable={false}
                                        required={true}
                                        options={this.state.role_options}
                                        onChange={(e) => this.setState({ 'role_id': e })}
                                        value={this.state.role_id}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label">Cost Book</label>
                                <div className="slds-form-element__control">
                                    <div className="SLDS_date_picker_width">
                                        <SLDSReactSelect
                                            simpleValue={true}
                                            className="custom_select default_validation"
                                            options={this.state.cost_book_options}
                                            onChange={(e) => this.setState({ 'cost_book_id': e })}
                                            value={this.state.cost_book_id || ''}
                                            clearable={true}
                                            searchable={true}
                                            placeholder="Please Select"
                                            required={false}
                                            name="Cost Book"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                    Apartment/Unit number</label>
                                <div className="slds-form-element__control">
                                    <input type="text"
                                        name="shipping_unit_number"
                                        placeholder="Apartment/Unit number"
                                        onChange={(e) => handleChange(this, e)}
                                        value={this.state.shipping_unit_number || ''}
                                        maxLength="30"
                                        className="slds-input"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" ><abbr className="slds-required" title="required">* </abbr>Shipping Address</label>
                                <div className="slds-form-element__control">
                                    <ReactGoogleAutocomplete className="slds-input add_input mb-1"
                                        placeholder="street, suburb state postcode, Country"
                                        name={"Shipping Address"}
                                        onPlaceSelected={(place) => this.setState({ shipping_address: place.formatted_address })}
                                        types={['address']}
                                        returntype={'array'}
                                        value={this.state.shipping_address || ''}
                                        onChange={(evt) => this.setState({ shipping_address: evt.target.value })}
                                        onKeyDown={(evt) => this.setState({ shipping_address: evt.target.value })}
                                        required={true}
                                        componentRestrictions={{ country: "au" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" >Phone</label>
                                <div className="slds-form-element__control">
                                    <input type="text" name="phone"
                                        placeholder="Phone"
                                        onChange={(e) => handleChange(this, e)}
                                        value={this.state.phone || ''}
                                        data-rule-phonenumber={true}
                                        className="slds-input"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" >Fax</label>
                                <div className="slds-form-element__control">
                                    <input type="text"
                                        name="fax"
                                        placeholder="Fax"
                                        onChange={(e) => handleChange(this, e)}
                                        value={this.state.fax || ''}
                                        maxLength="100"
                                        className="slds-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-2">
                    <div className="col-lg-6 col-sm-6 ">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01">Status</label>
                                <div className="slds-form-element__control">
                                    <div className="">
                                        <SLDSReactSelect
                                            name="view_by_status"
                                            className="SLDS_custom_Select default_validation"
                                            simpleValue={true}
                                            searchable={false}
                                            placeholder="Please Select"
                                            clearable={false}
                                            options={[{ value: "0", label: "Inactive" }, { value: "1", label: "Active" }]}
                                            onChange={(e) => this.setState({ 'status': e })}
                                            value={this.state.status}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </ExpandableSection>
        )
    }

    /**
     * rendering billing information
     */
    renderBillingInformation() {
        return (
            <ExpandableSection id="default-expandable-section" title="Billing Information">
                <div className="container-fluid">
                    <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" >Accounts Payable Phone</label>
                                <div className="slds-form-element__control">
                                    <input type="text" name="payable_phone"
                                        placeholder="Can include area code"
                                        onChange={(e) => handleChange(this, e)}
                                        value={this.state.payable_phone || ''}
                                        data-rule-phonenumber={true}
                                        className="slds-input"
                                        maxLength="18"
                                        data-rule-phonenumber={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" >Accounts Payable Email</label>
                                <div className="slds-form-element__control">
                                    <input type="text"
                                        name="payable_email"
                                        placeholder="Account Payable Email"
                                        onChange={(e) => handleChange(this, e)}
                                        value={this.state.payable_email || ''}
                                        maxLength="100"
                                        className="slds-input"
                                        data-rule-email="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="text-input-id-1">
                                    Apartment/Unit number</label>
                                <div className="slds-form-element__control">
                                    <input type="text"
                                        name="billing_unit_number"
                                        placeholder="Billing Apartment/Unit number"
                                        onChange={(e) => handleChange(this, e)}
                                        value={this.state.billing_unit_number || ''}
                                        maxLength="200"
                                        className="slds-input"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" >Billing Address</label>
                                <div className="slds-form-element__control">
                                    <ReactGoogleAutocomplete className="slds-input add_input mb-1"
                                        placeholder="street, suburb state postcode, Country"
                                        name={"Billing Address"}
                                        onPlaceSelected={(place) => this.setState({ billing_address: place.formatted_address })}
                                        types={['address']}
                                        returntype={'array'}
                                        value={this.state.billing_address || ''}
                                        onChange={(evt) => this.setState({ billing_address: evt.target.value })}
                                        onKeyDown={(evt) => this.setState({ billing_address: evt.target.value })}
                                        componentRestrictions={{ country: "au" }}
                                    />
                                </div>
                                <div className="slds-form-element__control">
                                    <div className="slds-checkbox">
                                        <input type="checkbox" id="check_preferred_phone" name="check_preferred_phone" onChange={(e) => this.sameAsShippingAddress(e)} checked={this.state.billing_same_as_shipping ? true : false} />
                                        <label className="slds-checkbox__label" htmlFor="check_preferred_phone">
                                            <span className="slds-checkbox_faux"></span>
                                            <span className="slds-form-element__label">Same as shipping address</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ExpandableSection>
        )
    }

    /**
     * rendering components
     */
    render() {
        return (
            <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                <Modal
                    isOpen={this.props.showModal}
                    footer={[
                        <Button disabled={this.state.loading} label="Cancel" onClick={() => this.props.closeModal(false)} />,
                        <Button disabled={this.state.loading} label="Save" variant="brand" onClick={this.onSubmit} />,
                    ]}
                    onRequestClose={this.toggleOpen}
                    heading={this.props.org_id && this.state.is_site == 1 ? "Update Site" :  "Add Site" }
                    size="small"
                    className="slds_custom_modal"
                    onRequestClose={() => this.props.closeModal(false)}
                    dismissOnClickOutside={false}
                >
                    <section className="manage_top" >
                        <form id="create_org" autoComplete="off" className="slds_form">
                            {this.renderAccountInformation()}
                            {this.renderBillingInformation()}
                        </form>
                    </section>
                </Modal>
            </IconSettings>
        );
    }
}

export default CreateSiteModel;