import React from 'react';
import { SectionContainer, Row, ListSearch, DataList, TableColumns, Col50, SelectList, ARF } from '../oncallui-react-framework';

class EmailTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            refresh: false,
            template_author: "all",
            template_folder: "all",
            filter: {
                template_author: "all",
                template_folder: "all"
            }
        }
    }

    searchList(search) {
        this.setState({ search, refresh: !this.state.refresh });
    }

    onTemplateClick(e, id, text) {
        this.props.onTemplateClick(e, id, text);
    }

    onFilterChange(key, value) {
        let filter = this.state.filter;
        filter[key] = value;
        this.setState({[key]: value, filter, refresh: !this.state.refresh});
    }

    render() {
        return (
            <SectionContainer>
                <Row border>
                    <DataList
                        apiUrl="imail/Templates/template_listing"
                        header={
                            <React.Fragment>
                                <Row>
                                    <Col50>
                                        <SelectList 
                                            label="Templates" 
                                            options={[
                                                {label: "My Templates", value: "my"},
                                                {label: "All Templates", value: "all"}
                                            ]}
                                            onChange={value => this.onFilterChange("template_author", value)}
                                            value={this.state.template_author}
                                        />
                                    </Col50>
                                </Row>
                                <Row>
                                    <Col50>
                                        <SelectList 
                                            label="Folder"
                                            options={[
                                                {label: "All", value: "all"},
                                                {label: "Private", value: "private"},
                                                {label: "Public", value: "public"}
                                            ]}
                                            onChange={value => this.onFilterChange("template_folder", value)}
                                            value={this.state.template_folder}
                                        />
                                    </Col50>
                                    <Col50>
                                        <ListSearch label="Search Templates" placeholder="Search Templates" onSearch={(search) => { this.searchList(search) }} />
                                    </Col50>
                                </Row>
                            </React.Fragment>
                        }
                        columns={
                            TableColumns([
                                { label: 'Template', accessor: 'name', onClick: (e, id, text) => { this.onTemplateClick(e, id, text) }, style: { "textDecoration": "underline", "cursor": "pointer" }, title: "Click to select template" },
                                { label: 'Description', accessor: 'subject' },
                                { label: 'Folder', accessor: 'folder', format: value => ARF.ucfirst(value) }
                            ])
                        }
                        search={this.state.search}
                        refresh={this.state.refresh}
                        filter={this.state.filter}
                    />
                </Row>
            </SectionContainer>
        )
    }
}

export default EmailTemplates;