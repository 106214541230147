import React, { Component } from 'react';
import { connect } from 'react-redux';
// import CreateShiftModal from './CreateShiftModal';
import { dispatchOpenCloseShiftModal } from '../../action/ShiftAction';
import CreateShiftModal from './shift/CreateShiftModal';
import ScheduleList from './shift/ScheduleList';

class ShiftList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    openCloseShiftModal = () => {
        this.props.dispatchOpenCloseShiftModal(this.props.isShowModal ? false : true);
    }

    render() {
        return (
            <div>
                <ScheduleList />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isShowModal: state.ShiftReducers.isShiftModalOpen,
})

const mapDispatchToProps = (dispatch) => ({
    dispatchOpenCloseShiftModal: (result) => dispatch(dispatchOpenCloseShiftModal(result))

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftList);