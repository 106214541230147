import React, { Component } from 'react';

class InternetConnection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            internetStatus: false
        }
    }


    componentDidMount() {
        this.setState({ internetStatus: window.navigator.onLine });
        window.addEventListener('offline', (e) => {
            this.setState({ internetStatus: false });
        });
        window.addEventListener('online', (e) => {
            this.setState({ internetStatus: true });
        });
    }

    handleReload() {
        window.location.reload();
    }

    render() {
        if (!this.state.internetStatus) {
            return (
                <React.Fragment>
                    <div>
                        <div className="error_bg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85vh' }}>
                            <div>
                                <h2 className="text-center">Oops!</h2>
                                <p className="text-center">There is no internet connection</p>
                                <div className="pt-4"><a className="but px-5 VEC_btn" style={{ width: '140px', marginLeft: 'auto', marginRight: 'auto' }} onClick={() => this.handleReload()}>Refresh</a></div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return this.props.children;

    }
}

export default InternetConnection;