import React from 'react'
import { ARF } from "../services/ARF";

const FormElement = function(props) {
    return (
        <div id={ARF.uniqid(props)} className="slds-form-element">
            {props.children}
        </div>
    )
}

export default FormElement