import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ROUTER_PATH } from '../config';
import { PrivateRoute } from './PrivateRoute';
import PageNotFound from '../components/PageNotFound';
import Login from '../components/Login';
import ForgotPassword from '../components/ForgotPassword';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import ErrorBoundary from '../service/ErrorBoundary';
import SetPassword from '../components/SetPassword';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { checkLoginWithReturnTrueFalse } from '../service/common';
import jQuery from 'jquery';
import ShiftList from '../components/schedule/ShiftList';
import UpdatePassword from '../components/UpdatePassword';
import ShiftDetails from '../components/schedule/shift/ScheduleDetails';
import ViewAccount from '../components/crm/pages/account/ViewAccount';
import ViewContact from '../components/crm/pages/contact/View/ViewContact';
import ListChildSites from '../components/crm/pages/account/ListChildSites';
import Reset_password from '../components/Reset_password';
import ListChildAccounts from '../components/crm/pages/contact/View/ListChildAccounts';
import GlobalMenu from '../components/GlobalMenu';
import ListChildContacts from '../components/crm/pages/account/ListChildContacts';
import ListChildOrg from '../components/crm/pages/account/ListChildOrg';
import AccountMembersList from '../components/crm/pages/account/AccountMembersList';
class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            isLeftInnerSidebarOpen: false
        }
    }


    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        document.addEventListener('DOMContentLoaded', function () {
            var div = jQuery('#navbarheader');
            if (jQuery(div).offset()) {
                var start = jQuery(div).offset().top; //top header
                start = start + 50;
                jQuery.event.add(window, "scroll", function () {
                    var p = jQuery(window).scrollTop();
                    if (p > start) {
                        jQuery(div).addClass('sticky-navbar');
                    } else {
                        jQuery(div).removeClass('sticky-navbar');
                    }
                });
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    /**
     * Define the view is mobeil or desktop
     */
    resize() {
        console.log('yes');
        this.setState({ isMobile: window.innerWidth < 769 ? true : false }, console.log(this.state.isMobile, window.innerWidth));
    }

    /**
     * Handle the responsive header menu
     */
    onclickLeftMenu = () => {
        this.setState(p => ({ isLeftInnerSidebarOpen: !p.isLeftInnerSidebarOpen }))
    }


    renderPushMenu() {
        console.log('yes')
        return (
            <div id="GlobalMenuParent">
                <GlobalMenu isMobile={this.state.isMobile} isLeftInnerSidebarOpen={this.state.isLeftInnerSidebarOpen} />
            </div>
        )
    }

    render() {
        var myStyle = { fontFamily: "Helvetica LT Std-1" };
        const { isLeftInnerSidebarOpen, isMobile } = this.state;
        const isLoggedIn = checkLoginWithReturnTrueFalse();
        const pageContentWrapperClassName = [isLeftInnerSidebarOpen && `open`, isLoggedIn ? `is-logged-in` : `is-guest`].filter(Boolean).join(' ');
        console.log(isMobile, pageContentWrapperClassName)
        return (
            <div>
                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                    <div id="wrapper" data-color="#00a7ee" style={myStyle} >
                        <div className="overlay_bg"></div>
                        <Router>
                            <React.Fragment>
                                {checkLoginWithReturnTrueFalse() && <React.Fragment>
                                    <input type="hidden" id="checkLoginStikcy" value={isLoggedIn} />
                                    <div id="navbarheader" className="topnavbar">
                                        <Header handleLeftMenu={this.onclickLeftMenu} />
                                    </div>
                                </React.Fragment>}
                                <div id="page-content-wrapper" className={pageContentWrapperClassName}>
                                    <div id="page-content-wrapper-inner">
                                        <span id="PushMenuCloseOverlay" onClick={() => this.setState({ isLeftInnerSidebarOpen: false })}></span>
                                        {isMobile && isLoggedIn && this.renderPushMenu()}
                                        <Switch>
                                            <Route exact path={'/'} render={() => <Login />} />
                                            <Route exact path={'/something_went_wrong'} render={() => <ErrorBoundary />} />
                                            <Route exact path={ROUTER_PATH + 'forgot_password'} component={ForgotPassword} />
                                            <Route exact path={ROUTER_PATH + 'reset_password/:id/:token/:date/:type'} component={SetPassword} />
                                            <Route exact path={ROUTER_PATH + 'reset_password/:id/:token/:dateTime/:type/:uuid_user_type'} component={Reset_password} />
                                            <Route exact path={ROUTER_PATH + 'forgot_password/:id'} component={SetPassword} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'schedule/list'} component={ShiftList} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'update_password'} component={UpdatePassword} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'schedule/details/:id'} component={ShiftDetails} />
                                            {/* <PrivateRoute exact path={ROUTER_PATH + 'organisation/details'} component={ViewAccount} /> */}
                                            <PrivateRoute exact path={ROUTER_PATH + 'contact/details/:id'} component={ViewContact} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'site/list'} component={ListChildAccounts} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'account/details/:id'} component={ViewAccount} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'contact/related-listing/:type/:id'} component={ListChildContacts} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'account/related-listing/:is_site/:id'} component={ListChildAccounts} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'organisation/listing/hierarchy/:id'} component={ListChildOrg} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'organisation/listing/sitehierarchy/:id'} component={ListChildSites} />
                                            <PrivateRoute exact path={ROUTER_PATH + 'account/member/:id'} component={AccountMembersList} />
                                            <Route path='*' component={PageNotFound} />

                                        </Switch>
                                    </div>
                                </div>

                                {(checkLoginWithReturnTrueFalse()) &&
                                    <Footer />
                                }
                            </React.Fragment>
                        </Router>
                        <ToastContainer />
                    </div>
                </IconSettings>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Routes);