import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '@salesforce/design-system-react/lib/components/icon';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import GlobalHeader from '@salesforce/design-system-react/lib/components/global-header';
import GlobalHeaderNotifications from '@salesforce/design-system-react/lib/components/global-header/notifications';
import GlobalHeaderProfile from '@salesforce/design-system-react/lib/components/global-header/profile';
import Popover from '@salesforce/design-system-react/lib/components/popover';
import moment from 'moment-timezone';
import Spinner from '@salesforce/design-system-react/lib/components/spinner';
import { Link, Redirect } from 'react-router-dom';
import './scss/details.scss';
import { getFullName, logout } from '../service/common';
import GlobalMenu from './GlobalMenu.jsx';
import { ROUTER_PATH } from 'config.js';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    navigateOptions = (url) => {
        window.location = ROUTER_PATH + url;
    }

    renderProfileContent = () => {
        return <>
            <div className="slds-theme_shade slds-p-left_small">Options</div>
            <div id="header-profile-custom-popover-content">
                <div className="slds-m-horizontal_small slds-p-vertical_x-small">
                    <div className="slds-tile slds-tile_board slds-m-horizontal_small">
                        <div className="slds-tile__detail">
                            <ul>
                                <li><a onClick={() => this.navigateOptions('update_password')} href="javascript:void(0);">Update Password</a></li>
                                <li><a href="#!"  onClick={() => logout()} >Log Out</a></li>
                                {/* <li><Link to="/admin/update_pin">Update Pin</Link></li>
                                <li><Link to="/admin/update_password_recovery_email">Update Password Recovery Email</Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    renderPushMenu() {

        return (
            <div >
                <GlobalMenu propsData={this.props} />
            </div>
        )
    }

    /**
     * Render humburger menu if screen width less than 768
     */
     renderMobileToggleMenu() {
        return (
            <div id='SidebarTogglerParent'>
                <span id="PushMenuCloseOverlay" onClick={() => this.setState({ isLeftInnerSidebarOpen: false })}></span>
                <button
                    type="button"
                    className="btn btn-humburger"
                    onClick={() => {
                        this.setState(p => ({ isLeftInnerSidebarOpen: !p.isLeftInnerSidebarOpen }))
                        this.props.handleLeftMenu();
                    }}
                >
                    {
                        this.props.isLeftInnerSidebarOpen === true ?
                            <Icon
                                assistiveText={{ label: 'User' }}
                                category="utility"
                                name="close"
                                size="small"
                            />
                        :
                            <i className="icon icon-menu"></i>
                    }

                </button>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <div className="overlay"></div>
                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                    <div className="dis-flex slds-header-mob-view">
                    { this.renderMobileToggleMenu() }
                        <GlobalHeader
                            logoSrc={process.env.PUBLIC_URL + "/assets/images/admin/ocs_logo.svg"}>
                            <GlobalHeaderNotifications
                                notificationCount={this.props.notification_unread_count}
                                popover={
                                    <Popover
                                        id={this.props.NotificationCount === 0 ? "cus-header-notifications-readed" : "cus-header-notifications"}
                                        align={'bottom right'}
                                        ariaLabelledby="header-notifications-custom-popover-content"
                                    // body={ }
                                    />
                                }
                            />
                            <GlobalHeaderProfile
                                popover={
                                    <Popover
                                        body={this.renderProfileContent()}
                                        id="header-profile-popover"
                                    />
                                }
                                userName={getFullName()}
                            />
                        </GlobalHeader>
                    </div>
                </IconSettings>
                {this.renderPushMenu()}
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);