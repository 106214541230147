import { postData, AjaxConfirm, toastMessageShow } from 'service/common.js';

export function get_sales_attachment_data(request_data) {
    return dispatch => {
        return postData('sales/Attachment/get_all_related_attachments', request_data).then(result => {
            if (result.status) {
                console.log(result.data);
                dispatch(setKeyValue(result.data));               
            } else {
                dispatch(setKeyValue([]));
            }
               
        })
    };

}

export const setKeyValue = (data) => {
    return {
        type: 'SET_KEY_VALUE_SALES_ATTACHMENT',
        data
    }
}
