import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { LOGIN_SVG, OCS_LOGO } from '../service/OcsConstant';
import jQuery from "jquery";
import $ from 'jquery';
import '../service/jquery.validate.js';
import "../service/custom_script.js";
import { postData, toastMessageShow } from '../service/common';
import { ROUTER_PATH } from '../config';


class SetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            password: '',
            confirm_password: '',
            error: '',
            success: '',
            validating: true,
            validateMassage: '',
            resetTrue: false,
            token: this.props.match.params.token ? this.props.match.params.token : null,
            id: this.props.match.params.id ? this.props.match.params.id : null
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        localStorage.clear();

        this.setState({ validateMassage: <span><h2 className="text-center">Please wait..</h2><p className="text-center">we are validating your request</p></span> });
        let data = {
            password_token: this.props.match.params.token,
            dateTime: this.props.match.params.date,
            req_from: this.props.match.params.type,
            id: this.props.match.params.id
        }

        postData('admin/login/verify_token', data).then((result) => {
            if (result.status) {
                this.setState({ validating: false, validateMassage: '' });
            } else {
                toastMessageShow(result.error, 'e');
                setTimeout(() => {
                    window.location = ROUTER_PATH;
                }, 2000);
            }
        });

    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, error: '', success: '' });
    }

    onSubmit(e) {
        e.preventDefault();

        jQuery('#reset_password').validate();
        if (!this.state.loading && jQuery('#reset_password').valid()) {
            this.setState({ loading: true, error: '', success: '' });
            if (this.state.password.length < 8) {
                this.setState({
                    error: <span><i className="icon ocs-warning2-ie"></i><div>The password must contains minimum 8 characters in length.</div></span>,
                    loading: false
                });
            } else {
                let data = {
                    req_from: this.props.match.params.type,
                    id: this.props.match.params.id,
                    password_token: this.props.match.params.token,
                    password: this.state.password,
                    isAuthorization: true,
                }
                // let url = this.state.token ? 'org_portal/Person/reset_password' : 'org_portal/Person/reset_password_forgot'
                postData('admin/login/set_password', data).then((result) => {
                    if (result.status) {
                        this.setState({ validating: true });
                        toastMessageShow(result.msg, 's');
                        setTimeout(() => {
                            window.location = ROUTER_PATH;
                        }, 2000);
                    } else {
                        toastMessageShow(result.error, 'e');
                        setTimeout(() => {
                            window.location = ROUTER_PATH;
                        }, 2000);
                    }
                });
            }
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.loading}>
                    <section className="gradient_color">

                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-md-offset-4">

                                    <div className="logo text-center"><img alt="logo" className="img-fluid" width="70px" src={OCS_LOGO} /></div>

                                    <div className="limiter">
                                        <div className="login_1">
                                            <div className="Smiley">
                                                <h1>
                                                    <span>Set password&nbsp;<i className="icon icon-smail-big"></i></span>
                                                </h1>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="User d-flex">
                                                    <span><img src={LOGIN_SVG} className="img-fluid align-self-center" alt="IMG" /></span>
                                                </div>
                                                {(!this.state.validating) ?
                                                    <form id="reset_password" className="login100-form" >
                                                        <div className="input_2">
                                                            <input className="input_3" id="password" type="password" name="password" placeholder="New password" onChange={this.handleChange} value={this.state.password} data-rule-strongPassword="true" min-length="8" data-rule-required="true" data-placement="right" />
                                                        </div>
                                                        <div className="input_2">
                                                            <input className="input_3" type="password" name="confirm_password" placeholder="Confirm password" onChange={this.handleChange} value={this.state.confirm_password} data-rule-required="true" data-rule-equalto="#password" data-msg-equalto="Please enter same password as above" data-placement="right" />
                                                        </div>
                                                        <div className="login_but">
                                                            <button onClick={this.onSubmit} className="but_login orange">Submit</button>
                                                        </div>
                                                        <span className="success_login s_new text-center">{this.state.success}</span>
                                                        <span className="text-center e_new error_login">{this.state.error}</span>
                                                    </form>
                                                    : <div>{this.state.validateMassage} </div>
                                                }


                                            </div>
                                            <h5 className="col-md-12 text-center P_30_T text-center for_text button_small"><Link to={'/'}>Login here</Link></h5>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer className="text-center">
                        <div className="container">
                            <div className="row">
                                <h6>© {moment().format("YYYY")} All Rights Reserved <span>Healthcare Manager</span></h6>
                            </div>
                        </div>
                    </footer>
                </BlockUi>
            </div>
        );
    }
}

export default SetPassword;