const initialState = {
    isShiftModalOpen: false
}


const ShiftReducers = (state = initialState, action) => {

    switch (action.type) {
        case 'SHIFTMODAL':
            return { ...state, isShiftModalOpen: action.payload };
        default:
            return state;
    }
}

export default ShiftReducers