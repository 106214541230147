import React from 'react'
import  SLDSReactSelect from '../salesforce/lightning/SLDSReactSelect';
import FormElement from './FormElement'
import Label from './Label'
import { ARF } from '../services/ARF';
/**
 * 
 * @param {object} props 
 *  wrapperClass | type | name | placeholder | label | maxLength | required{true,false} | value | className
 */
export const Lookup = function (props) {
    let _this = {
        label: props.label || props.name, 
        required: props.required,
        value: props.value,
        lookup: props.lookup,
        placeholder: props.placeholder || "Please Search"
    };
    return (
            <FormElement>
                <Label {..._this} />
                <div id={ARF.uniqid(props)} className={_this.wrapperClass}>
                    <SLDSReactSelect.Async 
                        placeholder={_this.placeholder} value={props.value} onChange={(e) => props.onChange(e)} loadOptions = {(e) => props.loadOptions(e)}
                    />
                </div>
            </FormElement>
    )
}