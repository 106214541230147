import { combineReducers } from 'redux';
import ShiftReducers from './ShiftReducers';
import ContactReducer from '../components/crm/reducer/ContactReducer';
import CrmParticipantReducer from '../components/crm/reducer/CrmParticipantReducer';
import DashboardReducer from '../components/crm/reducer/DashboardReducer';
import DepartmentReducer from '../components/crm/reducer/DepartmentReducer';
import ListViewControlActivityReducer from '../components/oncallui-react-framework/view/ListView/reducers/ListViewControlActivityReducer';
import SalesActivityReducer from '../components/crm/reducer/SalesActivityReducer';
import SalesAttachmentReducer from '../components/crm/reducer/SalesAttachmentReducer';

export default combineReducers({
    ShiftReducers,
    ContactReducer,
    CrmParticipantReducer,
    DashboardReducer,
    DepartmentReducer,
    ListViewControlActivityReducer,
    SalesAttachmentReducer,
    SalesActivityReducer
})
