import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Provider } from 'react-redux'
import moment from 'moment-timezone';
import store from './store/Store';
import 'simplebar/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import './components/scss/main-style.scss';
import InternetConnection from './service/InternetConnection';
import Routes from './navigation/Routes';

moment.tz.setDefault('Australia/Melbourne');

function App() {
  return (

    <Provider store={store}>
      <InternetConnection>
        <Routes />
      </InternetConnection>
    </Provider>
  );
}

export default App;
