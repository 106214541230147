
export const ROUTER_PATH = '/';
export const LOGIN_DIFFERENCE = 60; // 60 minits;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_OCS_GOOGLE_MAP_KEY;
export const WS_URL = process.env.REACT_APP_OCS_WS_URL;
export const PIN_DATA = {'fms':'1','admin':'2','incident':'3'};
export const BASE_URL = process.env.REACT_APP_OCS_API_HOST;
export * from './service/OcsConstant.js';
export const JIRA_URL = process.env.REACT_APP_OCS_JIRA_URL;
export const CMS_HOME_PAGE = process.env.REACT_APP_CMS_HOME_PAGE;
