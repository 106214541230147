import React, { Component } from 'react';
import moment from 'moment-timezone';
import { ROUTER_PATH, LOGIN_DIFFERENCE, BASE_URL, PIN_DATA } from '../config';
import { ToastUndo } from "./ToastUndo";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert, createElementReconfirm } from "react-confirm-alert"; // Import
import escapeRegExp from 'lodash.escaperegexp';
import axios from "axios";
import _ from "lodash";

export function setUserToken(token) {
    localStorage.setItem('ocstoken', token);
    localStorage.setItem('ocs_token603560', token);
}

export function getUserToken() {
    return localStorage.getItem('ocstoken');
}

export function getFullName() {
    return localStorage.getItem("user_name");
}

export function setFullName(full_name) {
    localStorage.setItem("user_name", full_name);
}

export function getPersonId() {
    return localStorage.getItem("person_id");
}

export function setPersonId(person_id) {
    localStorage.setItem("person_id", person_id);
}

export function getUUIDId() {
    return localStorage.getItem("uuid");
}

export function setUUIDId(person_id) {
    localStorage.setItem("uuid", person_id);
}



export function getLoginTIme() {
    return localStorage.getItem("dateTime");
}

export function setLoginTIme(token) {
    localStorage.setItem("dateTime", token);
}

export function check_loginTime() {
    var DATE_TIME = getLoginTIme();
    var server = moment(DATE_TIME);

    var currentDateTime = moment();

    const diff = currentDateTime.diff(server);
    const diffDuration = moment.duration(diff);

    if (diffDuration.days() > 0) {
        logout();
    } else if (diffDuration.hours() > 0) {
        logout();
    } else if (diffDuration.minutes() > LOGIN_DIFFERENCE) {
        logout();
    }
}

export function logout() {
    removeLogoutRequiredItem();
    setTimeout(function () {
        window.location = ROUTER_PATH;
        return true;
    }, 300);

}

export function removeLogoutRequiredItem() {
    localStorage.removeItem("ocstoken");
    localStorage.removeItem("dateTime");
    localStorage.removeItem("person_id");
    localStorage.removeItem("user_name");
    localStorage.clear();
}

export function checkLoginWithReturnTrueFalse() {
    if (!getUserToken()) {
        return false;
    } else {
        return true;
    }
}

export function checkItsNotLoggedIn() {
    check_loginTime();

    if (!getUserToken()) {
        window.location = ROUTER_PATH;
    }
}

export function getPermission() {
    var AES = require("crypto-js/aes");
    var SHA256 = require("crypto-js/sha256");
    var CryptoJS = require("crypto-js");
    var ciphertext = localStorage.getItem("permission");
    // Decrypt

    try {
        var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), "secret key 123");
        var plaintext = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
        var plaintext = undefined;
    }
    return plaintext;
}


export function postData(url, data, obj) {
    if (!data) {
        var data = [];
    }
    var request_data = { pin: '', data: data };
    // var data = { data };
    return new Promise((resolve, reject) => {

        const controller = new AbortController();
        const { signal } = controller;

        // request_data["signal"] = signal;

        fetch(BASE_URL + url, { signal, method: "POST", body: JSON.stringify({ request_data }), headers: getHeaders() })
            .then(response => response.json())
            .then(responseJson => {
                // if (url == "admin/Login/logout") {
                //     resolve(responseJson);
                //     return true;
                // }

                // if jwt token status true mean token not verified

                if (responseJson.token_status) {
                    logout();
                }

                // if token is verified then update date client side time
                if (responseJson.status) {
                    setLoginTIme(moment());
                }


                // if server status true mean request not came at over server
                if (responseJson.server_status) {
                    logout();
                }
                resolve(responseJson);

            })
            .catch(error => {
                if (error.name === "AbortError") {

                } else {
                    toastMessageShow("API ERROR", "e");
                    resolve({ status: false, error: "API ERROR" });
                }
            });

        if (obj) {
            obj.setState({ requestedMethod: controller })
        }

    });
}


export function getHeaders() {
    var headers_data = {};
    if (getUserToken() !== null) {
        headers_data['Authorization'] = 'Bearer ' + getUserToken();
    }
    return headers_data;
}


/**
 * @param {string} msg 
 * @param {'s'|'e'|'w'|'i'} type 
 * @param {{close?: Function, open?: Function}} callbackOption 
 */
export const toastMessageShow = (msg, type, callbackOption) => {
    if (msg) {
        if (msg == undefined || (typeof msg == "string" && msg == "")) {
            return false;
        }
        toast.dismiss();
        let options = {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        };
        if (callbackOption != undefined && typeof callbackOption == "object" && callbackOption != null) {
            if (callbackOption.hasOwnProperty("close")) {
                options["onClose"] = callbackOption.close;
            }
            if (callbackOption.hasOwnProperty("open")) {
                options["onOpen"] = callbackOption.open;
            }
        }
        if (type == "s") {
            toast.success(<ToastUndo message={msg} showType={type} />, options);
        } else if (type == "e") {
            toast.error(<ToastUndo message={msg} showType={type} />, options);
        } else if (type == "w") {
            toast.warn(<ToastUndo message={msg} showType={type} />, options);
        } else if (type == "i") {
            toast.info(<ToastUndo message={msg} showType={type} />, options);
        } else {
            toast.error(<ToastUndo message={msg} showType={type} />, options);
        }
    }
};

export function checkItsLoggedIn() {
    if (getUserToken()) {
        window.location = ROUTER_PATH + "schedule/list";
    }
}

/**
 * This is just to make strongly typed inline styling.
 * Only supports 1 level of styling
 *
 * @template K
 * @param {Record<keyof K, Partial<CSSStyleDeclaration>>} s
 * @return {Record<keyof K, Partial<CSSStyleDeclaration>>}
 */
export const css = s => s


export function handleChange(Obj, e) {
    if (e != undefined && e.target.pattern) {
        const re = eval(e.target.pattern);
        if (e.target.value != "" && !re.test(e.target.value)) {
            return;
        }
    }

    var state = {};
    state[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    Obj.setState(state);
}


export function queryOptionData(e, urlData, requestData, checkLengthData = 0, requestDataNotStringfy = 0) {
    console.log(e, urlData, requestData, checkLengthData = 0, requestDataNotStringfy = 0);
    if (!e || e.length < parseInt(checkLengthData)) {
        let blankData = Promise.resolve({ options: [] });
        return blankData.then(res => {
            return res;
        });
    }

    var Request = parseInt(requestDataNotStringfy) == 1 ? requestData : requestData;
    return postData(urlData, Request).then(response => {
        console.log(response);
        return { options: response };
    });
}


export function handlePasswordChangeProgress(obj, value) {

    var lowerCaseRegex = /[a-z]/;
    var upperCaseRegex = /[A-Z]/;
    var numberRegex = /[0-9]/;
    var specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;


    if (value.length >= 8) {
        obj.setState({ valueForChar: 100, iconForChar: true })
    } else {
        obj.setState({ valueForChar: 0, iconForChar: false })
    }

    if (numberRegex.test(value) && lowerCaseRegex.test(value) && upperCaseRegex.test(value)) {
        obj.setState({ alphaNumeric: 100, iconForAlphaNumeric: true });
    } else {
        obj.setState({ alphaNumeric: 0, iconForAlphaNumeric: false });
    }

    if (specialCharRegex.test(value)) {
        obj.setState({ specialChar: 100, iconForSpecialChar: true })
    } else {
        obj.setState({ specialChar: 0, iconForSpecialChar: false })
    }
}

export function handleDecimalChange(Obj, e) {

    if (e != undefined || e.target.value != "") {
        var t = e.target.value;
        e.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;

    }

    handleChange(Obj, e);

}

export async function handleShareholderNameChangeShift(obj, stateName, index, fieldName, value, e) {
    if (e) {
        e.preventDefault();
    }
    return new Promise(async (resolve, reject) => {
        if (e != undefined && e.target.pattern) {
            const re = eval(e.target.pattern);
            if (e.target.value != "" && !re.test(e.target.value)) {
                resolve({ status: false });
                return;
            }
        }
        var state = {};
        var List = obj.state[stateName];
        var durlabel = "duration_disabled";
        var timelabel = "timing_disabled";
        var rows_cat = (stateName == 'scheduled_rows') ? 1 : 2;
        List[index][fieldName] = value;
        if (fieldName == "break_start_time" || fieldName == "break_end_time") {
            if (value) {
                List[index][durlabel] = true;
            }
            else if (List[index]['break_start_time'] == '' && List[index]['break_end_time'] == '') {
                List[index][durlabel] = false;
            }

            if (List[index]['break_start_time'] != '' && List[index]['break_end_time'] != '') {
                let start_time_temp = List[index]['break_start_time'];
                let end_time_temp = List[index]['break_end_time'];

                var isStartValid = /^(((0[1-9])|(1[0-2])):([0-5])(0|5)\s(A|P)M)?$/.test(start_time_temp);
                var isStartValidAlt = /^((0[1-9]):([0-5])(0|5)\s(A|P)M)?$/.test(start_time_temp);

                if (isStartValid === true || isStartValidAlt === true) {
                    let start_split = start_time_temp.split(':');
                    let start_min_split = start_split[1].split(' ');
                    let start_hr_temp = start_split[0];
                    let start_min_temp = start_min_split[0];
                    let start_for_temp = start_min_split[1];
                    let start_hrminutes = String(start_hr_temp).padStart(2, '0') + ':' + String(start_min_temp).padEnd(2, '0') + ' ' + start_for_temp;
                    List[index]['break_start_time'] = start_hrminutes;
                }

                var isEndValid = /^(((0[1-9])|(1[0-2])):([0-5])(0|5)\s(A|P)M)?$/.test(end_time_temp);
                var isEndValidAlt = /^((0[1-9]):([0-5])(0|5)\s(A|P)M)?$/.test(end_time_temp);
                if (isEndValid === true || isEndValidAlt == true) {
                    let end_split = end_time_temp.split(':');
                    let end_min_split = end_split[1].split(' ');
                    let end_hr_temp = end_split[0];
                    let end_min_temp = end_min_split[0];
                    let end_for_temp = end_min_split[1];
                    let end_hrminutes = String(end_hr_temp).padStart(2, '0') + ':' + String(end_min_temp).padEnd(2, '0') + ' ' + end_for_temp;
                    List[index]['break_end_time'] = end_hrminutes;
                }

                var req = {
                    break_start_time: List[index]['break_start_time'],
                    break_end_time: List[index]['break_end_time']
                };
                const { status, data1, data2 } = await postData("schedule/ScheduleDashboard/calculate_break_duration", req);
                if (data1 && data1 != '') {
                    List[index]["duration_int"] = data1;
                }
                if (data2 && data2 != '') {
                    List[index]["break_duration"] = data2;
                }
            }
        }
        else if (fieldName == "break_duration") {
            if (value) {
                List[index][timelabel] = true;
            }
            else {
                List[index][timelabel] = false;
            }
        }

        var shift_duration = await calculate_shift_duration(rows_cat, obj.state, List);
        if (rows_cat == 1) {
            state['scheduled_duration'] = shift_duration;
        }
        else {
            state['actual_duration'] = shift_duration;
        }

        // if(stateName == 'scheduled_rows' && fieldName == 'break_type'){
        //     var List2 = obj.state['actual_rows'];
        //     List2[index][fieldName] = value;
        //     state['actual_rows'] = Object.assign([], List2);
        // }

        state[stateName] = Object.assign([], List);

        obj.setState(state, () => {
            resolve({ status: true });
        });
        //Pull the line items and active duration calculation on actual break druation change
        if (stateName == 'actual_rows' && fieldName == 'break_duration') {
            obj.calcActiveDuration(2, 'actual_break_rows')
        }
    });
}


export async function calculate_shift_duration(cat, state, break_rows, key, val) {
    var final_req;
    if (cat == 2) {
        final_req = {
            actual_start_date: state.actual_start_date,
            actual_end_date: state.actual_end_date,
            actual_start_time: state.actual_start_time,
            actual_end_time: state.actual_end_time,
            actual_rows: state.actual_rows,
            break_cat: cat
        };

        if (key && val) {
            final_req[key] = val;
        }
    }
    else {
        final_req = {

            scheduled_start_date: state.scheduled_start_date,
            scheduled_end_date: state.scheduled_end_date,
            scheduled_start_time: state.scheduled_start_time,
            scheduled_end_time: state.scheduled_end_time,
            scheduled_rows: state.scheduled_rows,
            break_cat: cat
        };
        if (key && val) {
            final_req[key] = val;
        }
    }

    if (final_req.scheduled_start_date && final_req.scheduled_end_date && final_req.scheduled_start_time && final_req.scheduled_end_time && cat == 1) {
        final_req['scheduled_start_date'] = moment(final_req['scheduled_start_date']).format('YYYY-MM-DD');
        final_req['scheduled_end_date'] = moment(final_req['scheduled_end_date']).format('YYYY-MM-DD');
        const { status, data } = await postData("schedule/ScheduleDashboard/calculate_shift_duration", final_req);
        if (status) {
            return data;
        }
    }
    else if (final_req.actual_start_date && final_req.actual_end_date && final_req.actual_start_time && final_req.actual_end_time && cat == 2) {
        final_req['actual_start_date'] = moment(final_req['actual_start_date']).format('YYYY-MM-DD');
        final_req['actual_end_date'] = moment(final_req['actual_end_date']).format('YYYY-MM-DD');
        const { status, data } = await postData("schedule/ScheduleDashboard/calculate_shift_duration", final_req);
        if (status) {
            return data;
        }
    }
    return '';
}

/**
 *
 * @param {Record<string, any>} data The data that will be posted via `postData` utility function
 * @param {string|number|JSX.Element} [msg] Confirmation message. You can use JSX.
 * @param {string} [url] The url that will be posted via `postData` utility function
 * @param {object} [extraParams] Additional options
 * @param {string|number|JSX.Element} [extraParams.confirm] Label for 'confirm' button
 * @param {string|number|JSX.Element} [extraParams.cancel] Label for 'Cancel' button
 * @param {string|number|JSX.Element} [extraParams.heading_title] Heading title
 * @param {string} [extraParams.content_body_class] Classname that will be appended to <body> element when the confirmation modal opens
 * @return {Promise<{[key: string]: any, status?: boolean, error?: string, msg?: string, data?: any}>}
 */
export const AjaxConfirm = (
    data,
    msg = <span>Are you sure you want to do this action?</span>,
    url = null,
    extraParams = {}) => {

    const options = {
        confirm: 'Confirm',
        cancel: 'Cancel',
        heading_title: 'Confirmation',
        content_body_class: '',
        // button_position_change: false, // This is not supported
        ...extraParams,
    }

    return new Promise((resolve, reject) => {
        let loading = false;

        const handleOnSuccess = (onClose, res) => {
            resolve(res);
            onClose();
        };

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <section
                        role="dialog"
                        tabindex="-1"
                        aria-labelledby="ConfirmModalHeading"
                        aria-modal="true"
                        aria-describedby="modal-content-id-1"
                        className={[`slds-modal slds-fade-in-open`, options.content_body_class].filter(Boolean).join(' ')}
                        style={{ fontFamily: `Salesforce Sans, Arial, Helvetica, sans-serif` }}
                    >
                        <div className={`slds-modal__container`}>
                            <div className="slds-modal__header">
                                <button
                                    className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
                                    title="Close" onClick={() => {
                                        onClose();
                                        resolve({ status: false });
                                    }}
                                >
                                    <svg className={`slds-button__icon slds-button__icon_large`} aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d={`M14.6 11.9l6-6c.3-.3.3-.7 0-1l-.9-1c-.3-.3-.7-.3-1 0L12.6 10c-.1.2-.4.2-.6 0L6 3.9c-.3-.3-.7-.3-1 0l-1 .9c-.3.3-.3.7 0 1l6.1 6.1c.1.1.1.4 0 .6L4 18.6c-.3.3-.3.7 0 1l.9.9c.3.3.7.3 1 0l6.1-6c.2-.2.5-.2.6 0l6.1 6c.3.3.7.3 1 0l.9-.9c.3-.3.3-.7 0-1l-6-6c-.2-.2-.2-.5 0-.7z`} />
                                        </svg>
                                    </svg>
                                    <span className="slds-assistive-text">Close</span>
                                </button>
                                <h2 id="ConfirmModalHeading" className="slds-modal__title slds-hyphenate">{options.heading_title}</h2>
                            </div>
                            <div className="slds-modal__content slds-p-around_medium" id="modal-content-id-1">
                                {msg}
                            </div>
                            <div className="slds-modal__footer">
                                <button
                                    type={`button`}
                                    className="slds-button slds-button_neutral"
                                    disabled={loading}
                                    onClick={() => {
                                        onClose();
                                        resolve({ status: false });
                                        loading = false;
                                    }}
                                >
                                    {options.cancel}
                                </button>
                                <ArchiveConfirmComponent
                                    onClosef={handleOnSuccess}
                                    confirm={options.confirm}
                                    url={url}
                                    data={data}
                                    onClosed={onClose}
                                    className={`slds-button slds-button_brand`}
                                />
                            </div>
                        </div>
                    </section>
                );
            }
        });
    });
};

/**
 *
 * @param {string|number|JSX.Element} [msg] Confirmation message. You can use JSX.
 * @param {object} [extraParams] Additional options
 * @param {string|number|JSX.Element} [extraParams.confirm] Label for 'confirm' button
 * @param {string|number|JSX.Element} [extraParams.cancel] Label for 'Cancel' button
 * @param {string|number|JSX.Element} [extraParams.heading_title] Heading title
 * @param {string} [extraParams.content_body_class] Classname that will be appended to <body> element when the confirmation modal opens
 * @return {Promise<{status: boolean}>}
 */
export const Confirm = (
    msg = <span>Are you sure you want to do this action?</span>,
    extraParams = {}) => {

    const options = {
        confirm: 'Confirm',
        cancel: 'Cancel',
        heading_title: 'Confirmation',
        content_body_class: '',
        // button_position_change: false, // This is not supported
        ...extraParams,
    }

    return new Promise((resolve, reject) => {
        let loading = false;

        const handleOnSuccess = (onClose, res) => {
            resolve(res);
            onClose();
        };

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <section
                        role="dialog"
                        tabindex="-1"
                        aria-labelledby="ConfirmModalHeading"
                        aria-modal="true"
                        aria-describedby="modal-content-id-1"
                        className={[`slds-modal slds-fade-in-open`, options.content_body_class].filter(Boolean).join(' ')}
                        style={{ fontFamily: `Salesforce Sans, Arial, Helvetica, sans-serif` }}
                    >
                        <div className={`slds-modal__container`}>
                            <div className="slds-modal__header">
                                <button
                                    className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
                                    title="Close" onClick={() => {
                                        onClose();
                                        resolve({ status: false });
                                    }}
                                >
                                    <svg className={`slds-button__icon slds-button__icon_large`} aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d={`M14.6 11.9l6-6c.3-.3.3-.7 0-1l-.9-1c-.3-.3-.7-.3-1 0L12.6 10c-.1.2-.4.2-.6 0L6 3.9c-.3-.3-.7-.3-1 0l-1 .9c-.3.3-.3.7 0 1l6.1 6.1c.1.1.1.4 0 .6L4 18.6c-.3.3-.3.7 0 1l.9.9c.3.3.7.3 1 0l6.1-6c.2-.2.5-.2.6 0l6.1 6c.3.3.7.3 1 0l.9-.9c.3-.3.3-.7 0-1l-6-6c-.2-.2-.2-.5 0-.7z`} />
                                        </svg>
                                    </svg>
                                    <span className="slds-assistive-text">Close</span>
                                </button>
                                <h2 id="ConfirmModalHeading" className="slds-modal__title slds-hyphenate">{options.heading_title}</h2>
                            </div>
                            <div className="slds-modal__content slds-p-around_medium" id="modal-content-id-1">
                                {msg}
                            </div>
                            <div className="slds-modal__footer">
                                <button
                                    disabled={loading}
                                    className="slds-button slds-button_neutral"
                                    onClick={() => {
                                        onClose();
                                        resolve({ status: false });
                                        loading = false;
                                    }}
                                >
                                    {options.cancel}
                                </button>
                                <button
                                    className="slds-button slds-button_brand"
                                    onClick={() => {
                                        onClose();
                                        resolve({ status: true });
                                        loading = false;
                                    }}
                                >
                                    {options.confirm}
                                </button>
                            </div>
                        </div>
                    </section>
                );
            }
        });
    });
};


/*
*Function copy from lightningdesignsystem comboxbox
*
**/
export function comboboxFilterAndLimit(inputValue, limit, options, selection) {
    const inputValueRegExp = new RegExp(escapeRegExp(inputValue), 'ig');
    // eslint-disable-next-line fp/no-mutating-methods
    return options
        .filter((option) => {
            const searchTermFound = option.label
                ? option.label.match(inputValueRegExp)
                : false;
            const isSeparator = option.type === 'separator';
            const notAlreadySelected = !selection.some((sel) => sel.id === option.id);

            return (
                (!inputValue || isSeparator || searchTermFound) && notAlreadySelected
            );
        })
        .splice(0, limit);
}


export function queryOptionDataAddNewEntity(e, urlData, requestData, checkLengthData = 0, requestDataNotStringfy = 0) {
    if (!e || e.length < parseInt(checkLengthData)) {
        let blankData = Promise.resolve({ options: [] });
        return blankData.then(res => {
            return res;
        });
    }

    var Request = parseInt(requestDataNotStringfy) == 1 ? requestData : JSON.stringify(requestData);
    return postData(urlData, Request).then(response => {
        return { options: response };
    });
}


/**
 * removes the lock of passed object type and unique id of that object from db for editing
 */
export async function remove_access_lock(object_type, object_id) {
    var data = {
        object_type: object_type,
        object_id: object_id
    };
    await postData("common/Common/remove_access_lock", data);
}


class ArchiveConfirmComponent extends Component {

    static defaultProps = {
        className: 'Confirm_btn_Conf'
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    clickFunction = () => {
        this.setState({ loading: true });
        postData(this.props.url, this.props.data).then(result => {
            this.props.onClosef(this.props.onClosed, result);
        });
    };
    componentWillUnmount() {
        this.setState({ loading: false });
    }

    render() {
        return (
            <button disabled={this.state.loading} className={this.props.className} onClick={() => this.clickFunction()}>
                {this.props.confirm}
            </button>
        );
    }
}

/**
 * takes the lock of object & object id if not taken otherwise returns false
 */
 export function get_take_access_lock(object_type, object_id, check_only = false) {
    return new Promise(async (resolve, reject) => {
        var data = {
            object_type: object_type,
            object_id: object_id,
            check_only: check_only
        };
        postData("common/Common/get_take_access_lock", data).then(postret => {
            resolve({ status: postret.status, error: postret.error });
        })
    });
}

export function handleChangeSelectDatepicker(Obj, selectedOption, fieldname) {
    return new Promise((resolve, reject) => {
        var state = {};
        state[fieldname] = selectedOption;

        if (Obj.state[fieldname + "_error"]) {
            state[fieldname + "_error"] = false;
        }

        if (fieldname == "state") {
            state["city"] = {};
            state["postal"] = "";
        }
        Obj.setState(state, () => {
            resolve({ status: true });
        });
    });
}

export function handleShareholderNameChange(obj, stateName, index, fieldName, value, e) {
    if (e) {
        e.preventDefault();
    }
    return new Promise((resolve, reject) => {
        if (e != undefined && e.target.pattern) {
            const re = eval(e.target.pattern);
            if (e.target.value != "" && !re.test(e.target.value)) {
                resolve({ status: false });
                return;
            }
        }
        var state = {};
        var List = obj.state[stateName];
        List[index][fieldName] = value;
        state[stateName] = Object.assign([], List);
        obj.setState(state, () => {
            resolve({ status: true });
        });
    });
}

export const selectFilterOptions = (options, filterValue, excludeOptions, props) => {
    if (excludeOptions)
        excludeOptions = excludeOptions.map(function (i) {
            return i[props.valueKey];
        });

    return options.filter(function (option) {
        if (excludeOptions && excludeOptions.indexOf(option[props.valueKey]) > -1) return false;
        if (props.filterOption) return props.filterOption.call(undefined, option, filterValue);
        if (!filterValue) return true;

        var value = option[props.valueKey];
        var label = option[props.labelKey];

        if (!value && !label) {
            return false;
        }

        var valueTest = value ? String(value) : null;
        var labelTest = label ? String(label) : null;

        return props.matchPos === "start" ? valueTest : valueTest;
    });
};

export function getLoginToken() {
    return localStorage.getItem("ocs_token603560");
}

export function postImageData(url, data, obj) {
    data.append("pin", getPinToken());

    return new Promise((resolve, reject) => {
        axios
        .post(BASE_URL + url, data, {  headers: getHeaders() }, {
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (obj != undefined && obj != null && typeof obj == "object" /* && obj.hasOwnProperty('progress') */) {
                        obj.setState({ progress: percentCompleted });
                    }
                }
            })
            .then(response => {
                response = response.data;

                // if jwt token status true mean token not verified
                if (response.token_status) {
                    logout();
                }

                // if token is verified then update date client side time
                if (response.status) {
                    setLoginTIme(moment());
                }

                // if pin status true mean pin token not verified
                if (response.pin_status) {
                    //destroyPinToken();
                    let type = response.hasOwnProperty("pin_type") ? response.pin_type : "";
                    destroyPinToken(type);
                }

                // if ip status true mean ip address change of current user
                if (response.ip_address_status) {
                    logout();
                }

                // if server status true mean request not came at over server
                if (response.server_status) {
                    logout();
                }

                // if permission status true mean not have permission to access this
                if (response.permission_status) {
                    window.location = "/admin/no_access";
                }

                resolve(response);
            })
            .catch(error => {
                reject(error);
                console.error(error);
            });
    });
}

export function getPinToken() {
    return localStorage.getItem("ocs_pin_token");
}

export function destroyPinToken(typeData) {
    if (typeof typeData == undefined || typeData == "") {
        localStorage.removeItem("ocs_pin_token");
        window.location = "/admin/dashboard";
    } else {
        let pinTypeData = getPinToken();
        let pinData = PIN_DATA;
        let type = typeData != "" && pinData.hasOwnProperty(typeData) ? pinData[typeData] : "";
        if (type == "") {
            let pinDataValue = _.invert(pinData);
            let typeKey = typeData != "" && pinDataValue.hasOwnProperty(typeData) ? pinDataValue[typeData] : "";
            type = typeKey != "" ? pinData[typeKey] : "";
        }
        pinTypeData = pinTypeData != null && pinTypeData != undefined && pinTypeData != "" ? JSON.parse(pinTypeData) : {};
        if (type != "" && pinTypeData.hasOwnProperty(type)) {
            delete pinTypeData[type];
            setPinToken(JSON.stringify(pinTypeData));
        }
        let urlRedirect = type == "3" && pinTypeData.hasOwnProperty("1") ? "/admin/fms/dashboard/new/case_ongoing" : "/admin/dashboard";
        window.location = urlRedirect;
    }
}

export function setPinToken(token) {
    return new Promise((resolve, reject) => {
        localStorage.setItem("ocs_pin_token", token);
        setTimeout(() => {
            resolve({ status: true });
        }, 100);
    });
}

export function handleRemoveShareholder(obj, e, index, stateName) {
    if (e) e.preventDefault();

    return new Promise((resolve, reject) => {
        var state = {};
        var List = obj.state[stateName];

        state[stateName] = List.filter((s, sidx) => index !== sidx);
        obj.setState(state, () => {
            resolve({ status: true });
        });
    });
}

export function reFreashReactTable(obj, fetchDataMethod) {
    var ReactOption = obj.reactTable.current.state;
    var state = { pageSize: ReactOption.pageSize, page: ReactOption.page, sorted: ReactOption.sorted, filtered: ReactOption.filtered };
    obj[fetchDataMethod](state);
}