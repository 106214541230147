import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import { Link } from 'react-router-dom';
import jQuery from "jquery";
import 'react-block-ui/style.css';
import { LOGIN_SVG, OCS_LOGO } from '../service/OcsConstant';
import { ROUTER_PATH } from '../config';
import { postData, setUserToken, setFullName, setPersonId, checkItsLoggedIn, setUUIDId } from '../service/common';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: '',
            success: '',
        };
        checkItsLoggedIn();
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({ error: '', [e.target.name]: e.target.value });
    }

    onSubmit = (e) => {
        e.preventDefault();

        jQuery('#login').validate();
        if (!this.state.loading && jQuery('#login').valid()) {
            this.setState({ loading: true });

            let data = {
                email: this.state.email,
                password: this.state.device_pin,
                user_type: 3,
                uuid_user_type: 3
            }
            postData('admin/Login/check_login', data).then((result) => {
                this.setState({ loading: false });
                if (result.status) {
                    this.setState({ success: <span><i className="icon icon-input-type-check"></i><div>{result.success}</div></span>, error: '' });
                    setUserToken(result.token);
                    setFullName(result.data.full_name);
                    setPersonId(result.data.person_id);
                    setUUIDId(result.data.id);
                    window.location = ROUTER_PATH + 'schedule/list';
                } else {
                    this.setState({ error: <span><i className="icon ocs-warning2-ie"></i><div>{result.error}</div></span> });
                }
            });
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.loading}>
                    <section className="gradient_color">
                        <div className="container">
                            <div className="row ">
                                <div className="col-md-4 col-md-offset-4">
                                    <div className="logo text-center">
                                        <a href="#"><img className="img-fluid" width="70px" src={OCS_LOGO} /></a>
                                    </div>

                                    <div className="limiter">
                                        <div className="login_1">
                                            <div className="Smiley">
                                                <h1><span>Login here<i className="icon icon-smail-big"></i></span></h1>
                                            </div>

                                            <form id="login" className="login100-form" onSubmit={this.onSubmit} noValidate>
                                                <div className="col-md-12">
                                                    <div className="User d-flex">
                                                        <span><img src={LOGIN_SVG} className="img-fluid align-self-center" alt="IMG" /></span>
                                                    </div>

                                                    <div className="input_2">
                                                        <input className="input_3" type="email" name="email" placeholder="Email" value={this.state['email'] || ''} onChange={(e) => this.setState({ email: e.target.value.replace(/\s/g, '') })} data-rule-required="true" data-placement="right" data-msg-email="Enter a valid email" />
                                                    </div>


                                                    <div className="input_2">
                                                        <input className="input_3 required" type="password" name="device_pin" placeholder="Password" value={this.state['device_pin'] || ''} onChange={this.handleChange} data-rule-required="true" data-placement="right" title={`Enter your password`} />
                                                    </div>

                                                    <div className="login_but">
                                                        <button className="but_login orange" >
                                                            Submit
                                                            </button>
                                                    </div>

                                                    <div className="success_login s_new">{this.state.success}</div>
                                                    <div className="error_login e_new">{this.state.error}</div>
                                                </div>
                                                <h5 className="col-md-12 text-center P_30_T text-center for_text">
                                                    <Link to={ROUTER_PATH + 'forgot_password'}>
                                                        Forgot Password?
                                                    </Link>
                                                </h5>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="text-center">
                        <div className="container">
                            <div className="row">
                                <h6>© {moment().format("YYYY")} All Rights Reserved <span>Healthcare Manager</span></h6>
                            </div>
                        </div>
                    </footer>
                </BlockUi>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);